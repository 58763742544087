import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { inject } from "@vercel/analytics";

inject();

ReactDOM.render(<App />, document.getElementById("root"));
