import { Link45deg as LinkIcon } from "react-bootstrap-icons";

function Link({ href, children }) {
  return (
    <a className="docs__link" href={`/docs/#${href}`}>
      <u>
        {children}
        <LinkIcon />
      </u>
    </a>
  );
}

export { Link };
