import Select from "../shared/components/Select";
import { data } from "./data";
import { useBusinessTypeContext } from "./index.js";
function AdvancedUseCases() {
  const { setBusinessType, businessType } = useBusinessTypeContext();
  return (
    <>
      <p>
        Praesenti permite la utilización de herramientas y funcionalidades mas
        avanzadas. Pero comprender su funcionamiento algunas veces puede ser
        dificil, por lo que diseñamos una documentación flexible, que se adapta
        a al tipo de negocio de tu empresa y te provee con ejemplos
        personalizados.
      </p>

      <p>
        Selecciona el tipo de negocio que tiene tu empresa, si no esta lo que
        estas buscando escribinos a{" "}
        <a href="mailto:help@praesenti.com.ar">help@praesenti.com.ar</a> para
        poder agregar esa opcion!
      </p>

      <Select
        label="Tipo de negocio"
        value={businessType}
        name="bussinessType"
        options={[...data.keys()].map((v) => ({
          id: v,
          name: v,
        }))}
        setValue={(v) => setBusinessType(v)}
      />
    </>
  );
}

export { AdvancedUseCases };
