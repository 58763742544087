import React from "react";
import "./Footer.css";
import WhiteLogo from "../assets/images/logo_blanco.webp";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  return (
    <footer className="footer">
      <Container className="footer__container">
        <Col>
          <Row>
            <div className="footer__gral-info">
              <Link to="/">
                <img src={WhiteLogo} className="footer__image" alt="Logo" />
              </Link>
              <p className="footer__text">
                Copyright © {new Date().getFullYear()} Praesenti
              </p>
              <a
                rel="noreferrer"
                className="footer__text-link"
                href="https://www.dpssoftware.com.ar"
                target="_blank"
              >
                <p>www.dpssoftware.com.ar</p>
              </a>
            </div>
          </Row>
          <hr className="footer__text" />
          <Row>
            <p className="footer__text footer__links-line">
              <Link to="/">Inicio</Link> |&nbsp;
              <Link to="/terms">Terminos y condiciones</Link> |&nbsp;
              <Link to="/docs/#">Documentación</Link> |&nbsp;
              {/* <Link to="/privacy">Politicas de privacidad</Link> |&nbsp; */}
              <a href="mailto:help@praesenti.com.ar">Contacto</a>
            </p>
          </Row>
        </Col>
      </Container>
    </footer>
  );
}

export default Footer;
