import axios from "axios";
import config from "../config";

const baseUrl = `${config.ip}/api/payments`;

async function getTiers() {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "get",
      url: `${baseUrl}/tiers`,
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          resolve(data);
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "Sin conexion" });
        switch (err.response.status) {
          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function updatePlan({ token, action }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "put",
      url: `${baseUrl}/subscription/status/${action}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          resolve(data);
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 400:
            reject({
              error: true,
              message: "La accion no es valida",
            });
            break;

          case 401:
            reject({
              error: true,
              message: "la session expiro, por favor, vuelve a hacer login",
            });
            break;

          case 403:
            reject({ error: true, message: "Permisos insuficientes" });
            break;

          case 409:
            reject({
              error: true,
              message:
                "No es posible realizar esta accion debido al estado del plan",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({ error: true, message: err?.response?.data?.message });
            break;
        }
      });
  });
}

export const paymentsService = {
  getTiers,
  updatePlan,
};
