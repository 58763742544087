import React, { useState } from "react";

import { sessionService } from "../services/session";

import "./shared.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Spinner } from "react-bootstrap";
import Header from "./components/Header";
import SendedImage from "./assets/images/9.webp";
import ErrorImage from "./assets/images/128.webp";
import ForgotImage from "./assets/images/186.webp";
import Button from "./components/Button";
import InputText from "./components/InputText";

const Confirm = () => {
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [email, setEmail] = useState("");

  function askForRestore() {
    setState({ loading: true, error: false, success: false });
    sessionService
      .askForRestorePassword({ email })
      .then(() => {
        setState({ loading: false, error: false, success: true });
      })
      .catch((err) => {
        setState({ loading: false, error: true, message: err.message });
      });
  }

  return (
    <>
      <Header />
      <section className="centered_section">
        <Modal
          show={state.error}
          onHide={() => {
            setState({});
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Se produjo un error"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img style={{ width: "60%" }} src={ErrorImage} alt="Error" />
              <div className="email-confirmation__description">
                {state.message ??
                  "Ocurrio un error, revisa que tu mail sea correcto y volve a intentarlo"}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {state.loading ? (
          <>
            <Spinner
              style={{
                width: "6vh",
                height: "6vh",
                color: "#4c399f",
                borderWidth: "0.4em",
              }}
              animation="border"
              size="lg"
            />
            <h2 className="email-confirmation__title">Enviando</h2>
          </>
        ) : (
          <>
            {state.success ? (
              <>
                <img
                  className="email-confirmation__image"
                  src={SendedImage}
                  alt="Confirmation"
                />
                <h2 className="email-confirmation__title">Revisa tu correo</h2>
                <div className="email-confirmation__description">
                  Te enviamos un correo con las instrucciones para restablecer
                  tu contraseña
                </div>
              </>
            ) : (
              <div>
                <div className="email-confirmation__image-container">
                  <img
                    className="email-confirmation__image"
                    src={ForgotImage}
                    alt="Email confirmation"
                  />
                </div>
                <h2 className="email-confirmation__title">
                  ¿Olvidaste tu contraseña?
                </h2>
                <div className="email-confirmation__description">
                  No te preocupes, ingresa tu correo y te enviaremos un link
                  para restablecerla
                </div>
                <div>
                  <InputText
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    label="Email"
                  ></InputText>
                  <Button
                    disabled={email === ""}
                    loading={state.loading}
                    onClick={askForRestore}
                    large={true}
                    filled={true}
                    text="Enviar"
                  ></Button>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default Confirm;
