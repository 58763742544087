import { Image } from "./components/Image";
import { DashboardItem } from "./components/DashboardItem";
import { Clipboard, Trash, EyeFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import RegistersTable from "./images/registers_table.png";
import RegistersEditModal from "./images/registers_edit_modal.png";
import RegistersCreateModal from "./images/registers_create_modal.png";
import RegistersCollapsableOpen from "./images/registers_collapsable_open.png";

function Registers() {
  return (
    <>
      <h3>Tabla de fichadas</h3>
      <p>
        En la sección de fichadas se encuentra la tabla que contiene todas las
        fichadas para todos los empleados. Las fichadas que esten en servicio
        (las que representan a los empleados que estan actualmente en el lugar
        de trabajo) seran las primeras en la tabla, las que no esten en servicio
        estaran ordenadas por fecha y horario de ingreso.
      </p>
      <p>
        Aquellas fichadas que hayan sido creadas o modificadas manualmente estan
        marcadas con un círculo azul en el el ingreso o en el egreso si han sido
        modificadas o en ambos campos si han sido creadas manualmente.
      </p>
      <p>
        Para ver la tabla de fichadas dirigase a la sección de fichadas en el{" "}
        <b>dashboard</b>:
      </p>
      <DashboardItem
        active
        text="Fichadas"
        Icon={Clipboard}
        to={"dashboard/registers"}
      />
      <Image src={RegistersTable} />

      <h3>Fichadas de un empleado</h3>
      <p>
        En la sección de empleados, al entrar al detalle del empleado, debajo de
        sus datos, se encuentra el desplegable de fichadas para ese empleado en
        especifico. Aqui se pueden crear, editar o eliminar fichadas manualmente
        en el caso de que se necesite. Esto puede ser cuando un empleado se
        olvide de fichar su ingreso o egreso del lugar de trabajo.
      </p>
      <Image src={RegistersCollapsableOpen} />
      <p>
        En cada fila el boton{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#3c4b64", borderColor: "#3c4b64" }}
        >
          <EyeFill />
        </Button>{" "}
        lleva al detalle del empleado correspondiente de la fichada para poder
        editar la fichada en caso de ser necesario. Por otro lado, el boton{" "}
        <Button size="sm" variant="danger">
          <Trash />
        </Button>{" "}
        permite eliminar una fichada.
      </p>
      <h3>Creando una fichada</h3>
      <p>
        Si bien no se recomienda crear o modificar fichadas manualmente
        entendemos que a veces es necesario. Por eso Praesenti permite crear y
        modificar fichadas en cualquier punto de la historia. Si es necesario
        hacer una modificación de una fichada reciente o de una fichada antigua,
        Praesenti te permite hacerlo.
      </p>
      <p>
        Para crear una fichada manualmente simplemente hay que ingresar la fecha
        y el horario de ingreso + el área y la categoría que tiene o tenía el
        empleado en la fecha de ingreso y egreso.
      </p>
      <p>
        Si un empleado hoy en día esta en el área "A" y categoría "B" pero se
        quiere modificar o crear una fichada de unos meses atrás cuando el
        empleado pertenecia a el área "X" y a la categoría "Y", simplemente hay
        que cambiar el área y la categoría a las correspondientes.
      </p>
      <p>
        (Tengase en cuenta que al crear la nueva fichada manualmente es como si
        el empleado hubiese fichado su ingreso leyendo el QR con la aplicacion,
        para registrar el egreso hay que editar la fichada y agregarle la fecha
        y horario de egreso)
      </p>
      <Image src={RegistersCreateModal} />
      <h3>Editando una fichada</h3>
      <p>
        El boton{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#3c4b64", borderColor: "#3c4b64" }}
        >
          <EyeFill />
        </Button>{" "}
        permite editar una fichada. En el caso de que la fichada este en
        servicio, no tendra fecha de egreso. Para cerrar una fichada en servicio
        simplemente agregue la fecha de egreso correspondiente y esta
        automaticamente se cerrara como si el empleado hubiese leído el codigo
        QR con la aplicación de teléfono a la hora de irse del lugar de trabajo.
      </p>
      <Image src={RegistersEditModal} />
    </>
  );
}

export { Registers };
