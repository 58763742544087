export const data = new Map([
  [
    "Restaurant",
    {
      gender: "masc",
      areasAndCategories: [
        { name: "Cocina", categories: ["Chefs", "Cocineros"] },
        { name: "Salon", categories: ["Mozos"] },
        { name: "Ballet Parking", categories: ["Seguridad", "Conductores"] },
      ],
      shifts: [
        {
          name: "Cocina",
          hours: "17:00-01:30",
          days: ["lun", "mar", "mier", "jue", "vie", "sab", "dom"],
        },
        {
          name: "Seguridad",
          hours: "17:00-01:30",
          days: ["lun", "mar", "mier", "jue", "vie", "sab", "dom"],
        },
        {
          name: "Mozos mañana",
          hours: "08:00-18:00",
          days: ["lun", "mar", "mier", "jue", "vie", "sab", "dom"],
        },
        {
          name: "Mozos tarde-noche",
          hours: "18:00-02:00",
          days: ["lun", "mar", "mier", "jue", "vie", "sab", "dom"],
        },
        {
          name: "Conductores",
          hours: "19:00-00:30",
          days: ["lun", "mar", "mier", "jue", "vie", "sab", "dom"],
        },
      ],
      texts: {
        areasIntro: `Un Restaurant tiene varios empleados, algunos empleados en la cocina,
        como chefs y cocineros, otros empleados en las mesas, como los mozos y
        también empleados en el ballet parking, como el personal de seguriadad
        y aquellos encargados de estacionar los autos.`,
        shiftsIntro: `El turno cocina podria englobar a los chefs y cocineros. Todos los que
        pertenezcan a ese turno tendran que ir al restaurant de 17:00 a 01:30
        para poder cumplir con el horario. Por otro lado, tenemos también dos
        turnos de mozos para los mismos dias, pero tienen
        asignados diferentes horarios. El turno de "Mozos mañana" debe asistir al lugar
        de trabajo de 08:00 a 18:00, mientras que el turno de
        "Mozos tarde-noche", debe asistir de 18:00 a 02:00`,
      },
    },
  ],
  [
    "Carpinteria Metalica",
    {
      gender: "fem",
      areasAndCategories: [
        { name: "Taller", categories: ["Soldadores", "Operarios"] },
        { name: "Oficina", categories: ["Administradores", "Diseñadores"] },
      ],
      shifts: [
        {
          name: "Taller",
          hours: "8:00-17:00",
          days: ["lun", "mier", "vie"],
        },
        {
          name: "Administradores",
          hours: "8:00-19:00",
          days: ["lun", "mar", "mier", "jue", "vie", "sab", "dom"],
        },
        {
          name: "Diseñadores",
          hours: "9:00-18:00",
          days: ["lun", "mier", "vie"],
        },
      ],
      texts: {
        areasIntro: `Una Carpinteria tiene varios, algunos empleados trabajan en el taller
        como los, soldadores y operarios, mientras que otros trabajan en la
        oficina, como por ejemplo, los administradores y diseñadores.`,
        shiftsIntro: `El turno taller podria englobar a los soldadores y operarios. Todos
        los que pertenezcan a ese turno tendran que ir al taller de 8:00 a
        17:00 para poder cumplir con el horario. Por otro lado, tenemos al
        turno "diseñadores" donde todos los empleados que sean parte deberan
        asistir al lugar de trabajo los Lunes, Miercoles y Viernes de 9:00 a
        18:00.`,
      },
    },
  ],
]);

export function pronounSwitch(businessType, mascOption, femOption) {
  return data.get(businessType).gender === "masc" ? mascOption : femOption;
}
