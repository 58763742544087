import axios from "axios";
import config from "../config";

const baseUrl = `${config.ip}/api/auth`;

async function login({ username, password }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "post",
      url: `${baseUrl}/login`,
      auth: {
        username,
        password,
      },
    })
      .then((response) => {
        const { success, token, data, message } = response.data;
        if (success) {
          const {
            email,
            name,
            lastname,
            org_id,
            org,
            scopes,
            chg_password,
            tour_finished,
            area,
          } = data;
          resolve({
            error: false,
            message,
            payload: {
              area,
              chg_password,
              email,
              name,
              lastname,
              tour_finished,
              token,
              org_id,
              org,
              scopes,
            },
          });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 401:
            reject({ error: true, message: "Email o contraseña incorrecta" });
            break;

          case 403:
            reject({
              error: true,
              verification: true,
              message: "Cuenta no verificada",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function checkSession({ token }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "get",
      url: `${baseUrl}/session`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { success, token, data, message } = response.data;
        if (success) {
          const {
            email,
            name,
            lastname,
            org_id,
            org,
            scopes,
            area,
            tour_finished,
          } = data;
          resolve({
            error: false,
            message,
            payload: {
              area,
              email,
              name,
              lastname,
              token,
              org_id,
              org,
              tour_finished,
              scopes,
            },
          });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 401:
            reject({
              error: true,
              message: "la session expiro, por favor, vuelve a hacer login",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function confirmAccount({ token }) {
  return new Promise(async (resolve, reject) => {
    if (!token)
      return reject({ error: true, message: "El token es requerido" });
    axios({
      method: "post",
      url: `${baseUrl}/confirm/${token}`,
    })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          resolve({ error: false, success });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 404:
            reject({
              error: true,
              message: "El token de validación es necesario",
            });
            break;

          case 401:
            reject({
              fatal: true,
              error: true,
              message: "La validación expiro, por favor solicita un nuevo mail",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function askForNewEmail({ email }) {
  return new Promise(async (resolve, reject) => {
    if (!email)
      return reject({ error: true, message: "El email es requerido" });
    axios({
      method: "put",
      url: `${baseUrl}/confirm`,
      data: { email },
    })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          resolve({ error: false, success });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 400:
            reject({ error: true, message: "El mail es obligatorio" });
            break;

          case 404:
            reject({
              error: true,
              message: "El mail introducido no fue encontrado",
            });
            break;

          case 409:
            reject({
              error: true,
              message: "El mail ingresado ya se encuentra confirmado",
            });
            break;

          // case 401:
          //   reject({fatal: true, error: true, message: 'La validación expiro, por favor solicita un nuevo mail'})
          // break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function askForRestorePassword({ email }) {
  return new Promise(async (resolve, reject) => {
    if (!email)
      return reject({ error: true, message: "El email es requerido" });
    axios({
      method: "put",
      url: `${baseUrl}/restore`,
      data: { email },
    })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          resolve({ error: false, success });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 400:
            reject({ error: true, message: "El mail es obligatorio" });
            break;

          case 404:
            reject({
              error: true,
              message: "El mail introducido no fue encontrado",
            });
            break;

          // case 401:
          //   reject({fatal: true, error: true, message: 'La validación expiro, por favor solicita un nuevo mail'})
          // break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function restorePassword({ token, newPassword }) {
  return new Promise(async (resolve, reject) => {
    if (!token)
      return reject({ error: true, message: "El token es requerido" });
    axios({
      method: "post",
      url: `${baseUrl}/restore/${token}`,
      data: { password: newPassword },
    })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          resolve({ error: false, success });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 400:
            reject({
              error: true,
              message: "La nueva contraseña es necesaria",
            });
            break;

          case 404:
            reject({
              error: true,
              message: "El token de validación es necesario",
            });
            break;

          case 401:
            reject({
              fatal: true,
              error: true,
              message: "La validación expiro, por favor solicita un nuevo mail",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function chgPassword({ token, currentPassword, newPassword }) {
  return new Promise(async (resolve, reject) => {
    if (!token)
      return reject({ error: true, message: "El token es requerido" });
    axios({
      method: "patch",
      url: `${baseUrl}/password`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        newPassword,
        oldPassword: currentPassword,
      },
    })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          resolve({ error: false, success });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 400:
            reject({
              error: true,
              message: "La nueva contraseña es necesaria",
            });
            break;

          case 401:
            reject({
              fatal: true,
              error: true,
              message: "La contraseña introducida no es correcta",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function finishTour({ token, type }) {
  return new Promise(async (resolve, reject) => {
    if (!token)
      return reject({ error: true, message: "El token es requerido" });
    axios({
      method: "patch",
      url: `${baseUrl}/tour`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        type,
      },
    })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          resolve({ error: false, success });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 401:
            reject({
              fatal: true,
              error: true,
              message: "La sesión caduco",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function register({
  username,
  password,
  name,
  lastname,
  org_name,
  phone,
  where,
}) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "post",
      url: `${baseUrl}/register`,
      data: {
        org_name,
        org_type: 1,
        owner: {
          email: username,
          password,
          name,
          phone,
          where,
          lastname,
        },
      },
    })
      .then((response) => {
        const { success, message } = response.data;
        if (success) {
          resolve({
            error: false,
            message,
          });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
            status: response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        let responseError = {
          error: true,
          message: "",
          status: err.response.status,
        };
        switch (err.response.status) {
          case 400:
            responseError.message = `Se produjo un error validando los datos, revisa los campos y vuelve a intentarlo. ${err?.response?.data?.message}`;
            break;

          case 409:
            responseError.message =
              "Ya existe un usuario registrado con ese correo";
            break;

          case 500:
            responseError.message =
              "Se produjo un error, intentalo denuevo más tarde";
            break;

          default:
            responseError.message = `Error desconocido [${err?.response?.data?.message}]`;
            break;
        }
        reject(responseError);
      });
  });
}

export const sessionService = {
  restorePassword,
  askForRestorePassword,
  askForNewEmail,
  confirmAccount,
  login,
  checkSession,
  register,
  chgPassword,
  finishTour,
};
