import React from "react";
import { Link } from "react-router-dom";

import "./sidebarItem.css";

const SidebarItem = (props) => {
  if (props.to) {
    return (
      <Link
        id={props.id}
        onClick={props.onClick}
        to={`/${props.to}`}
        className={` ${
          props.active === `/${props.to}` ? "sidebar__item-active" : ""
        } sidebar__item`}
      >
        <span className="sidebar__item-icon">{props.icon}</span>
        <span
          className={`${
            props.opened ? "" : "sidebar__item-title-hidden"
          } sidebar__item-title`}
        >
          {props.text}
        </span>
      </Link>
    );
  }

  return (
    <span
      id={props.id}
      onClick={props.onClick}
      className="sidebar__item sidebar__item-button"
    >
      <span className="sidebar__item-icon">{props.icon}</span>
      <span
        className={`${
          props.opened ? "" : "sidebar__item-title-hidden"
        } sidebar__item-title`}
      >
        {props.text}
      </span>
    </span>
  );
};

export default SidebarItem;
