import React, { useEffect, useState } from "react";

import { useParams, useNavigate, Link } from "react-router-dom";
import { sessionService } from "../services/session";

import "./shared.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Spinner } from "react-bootstrap";
import Header from "./components/Header";
import useUser from "../hooks/useUser";
import SuccessImage from "./assets/images/359.webp";
import ErrorImage from "./assets/images/128.webp";
import Button from "./components/Button";
import InputText from "./components/InputText";

const Confirm = () => {
  let { token } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [newEmailState, setNewEmailState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [email, setEmail] = useState("");
  const { isLogged } = useUser();

  function confirmAccount({ token }) {
    setState({ loading: true, error: false, success: false });
    sessionService
      .confirmAccount({ token })
      .then(() => {
        setState({ loading: false, error: false, success: true });
      })
      .catch((err) => {
        setState({
          loading: false,
          error: true,
          message: err.message,
          fatal: err.fatal,
        });
      });
  }

  function askForNewEmail() {
    setNewEmailState({ loading: true, error: false, success: false });
    sessionService
      .askForNewEmail({ email })
      .then(() => {
        setNewEmailState({ loading: false, error: false, success: true });
      })
      .catch((err) => {
        setNewEmailState({ loading: false, error: true, message: err.message });
      });
  }

  // FIX: hook dependency array
  useEffect(() => {
    if (!token) navigate("/");
    if (isLogged) navigate("/dashboard");
    if (!state.loading && !state.error && !state.success)
      confirmAccount({ token });
  }, [isLogged, state]);

  return (
    <>
      <Header />
      <section className="centered_section">
        <Modal
          show={newEmailState.error || newEmailState.success}
          onHide={() => {
            setNewEmailState({});
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {newEmailState.error ? "Se produjo un error" : "Listo!"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {newEmailState.error ? (
                <>
                  <img style={{ width: "60%" }} src={ErrorImage} alt="Error" />
                  <div className="email-confirmation__description">
                    {newEmailState.message ??
                      "Ocurrio un error, revisa que tu mail sea correcto y volve a intentarlo"}
                  </div>
                </>
              ) : (
                <>
                  <img
                    style={{ width: "60%" }}
                    src={SuccessImage}
                    alt="Success"
                  />
                  <div className="email-confirmation__description">
                    Ya enviamos el mail, revisa tu casilla de correo
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {state.loading ? (
          <>
            <Spinner
              style={{
                width: "6vh",
                height: "6vh",
                color: "#4c399f",
                borderWidth: "0.4em",
              }}
              animation="border"
              size="lg"
            />
            <h2 className="email-confirmation__title">VERIFICANDO CUENTA</h2>
          </>
        ) : (
          <>
            {state.success ? (
              <>
                <img
                  className="email-confirmation__image"
                  src={SuccessImage}
                  alt="Email confirmation"
                />
                <h2 className="email-confirmation__title">LISTO!</h2>
                <div className="email-confirmation__description">
                  Tu cuenta ya esta verificada! <br /> por favor inicia sesion:
                </div>
                <Link to="/login">
                  <Button filled={true} text="Iniciar Sesion"></Button>
                </Link>
              </>
            ) : (
              <div>
                <div className="email-confirmation__image-container">
                  <img
                    className="email-confirmation__image"
                    src={ErrorImage}
                    alt="Error in email confirmation"
                  />
                </div>
                <h2 className="email-confirmation__title">OCURRIO UN ERROR</h2>
                <div className="email-confirmation__description">
                  {state.message ?? "ocurrio un error"}
                </div>
                {state.fatal ? (
                  <div>
                    <InputText
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      id="email"
                      label="Email"
                    ></InputText>
                    <Button
                      disabled={email === ""}
                      loading={newEmailState.loading}
                      onClick={askForNewEmail}
                      large={true}
                      filled={true}
                      text="Solicitar nuevo mail"
                    ></Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        confirmAccount({ token });
                      }}
                      filled={false}
                      text="Reintentar"
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default Confirm;
