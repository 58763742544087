import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Header from "../shared/components/Header";
import Footer from "../shared/components/Footer";
import firstSectionImage from "../shared/assets/images/64.webp";
import secondSectionImage from "../shared/assets/images/19.webp";
import "./styles.css";
import Button from "../shared/components/Button";
import InputText from "../shared/components/InputText";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [username, setUsername] = useState("");

  return (
    <>
      <Header />
      <section className="landing__section">
        <Container className="landing__section-login">
          <Row>
            <Col className="landing__section-text left-text">
              <h2 className="landing__section-title">
                El control de fichadas <u>simple y agil</u>
              </h2>
              <p style={{ fontSize: "14pt" }}>
                Tenes en tus manos una poderosa herramienta que te facilita el
                acceso a la información de las fichadas de tus colaboradores.
                <br />
                Ademas generas con un solo un click todos los informes que
                necesitas!
              </p>
            </Col>
            <Col className="landing__section-largeimage">
              <img
                className="landing__section-image"
                src={secondSectionImage}
                alt="landing"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="landing__section color-00">
        <Container className="landing__section-login">
          <Row>
            <Col className="landing__section-text">
              <h2 className="landing__section-title">Registra tu empresa</h2>
              <Row>
                <Col>
                  <InputText
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    placeholder="ejemplo@mail.com"
                    name="email"
                  />
                </Col>
                <Col md="auto" className="landing__section-button">
                  <Link
                    to="/register"
                    state={{
                      username,
                    }}
                  >
                    <Button filled={true} text="Comenzar" large={false} />
                  </Link>
                </Col>
              </Row>
              <p>
                1- Registras tu organización en Praesenti. <br />
                2- Registras a tus empleados.
                <br />
                3- Cada empleado descarga la App de Praesenti en su celu.
                <br />
                4- Al entrar/salir de la organizacion, cada empleado escanea el
                QR y ¡listo!
                <br />
                <br />
                <b>
                  ¡Tenes ONLINE toda la información relacionada a
                  ingresos/egresos!
                </b>
              </p>
            </Col>
            <Col className="landing__section-largeimage">
              <img
                className="landing__section-image"
                src={firstSectionImage}
                alt="landing"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default LandingPage;
