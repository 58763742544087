import { useBusinessTypeContext } from "./index";
import { data } from "./data";
import { DashboardItem } from "./components/DashboardItem";
import { GearFill, PencilSquare, Trash } from "react-bootstrap-icons";
import { Image } from "./components/Image";
import { Button } from "react-bootstrap";

import AreasTableInit from "./images/areas_table_init.png";
import AreaCreate from "./images/area_create.png";
import AreaEdit from "./images/area_edit.png";
import AreaEdited from "./images/area_edited.png";
import AreaDelete from "./images/area_delete.png";
import AreaDisable from "./images/area_disable.png";
import AreaEmployee from "./images/area_employee.png";
import { Link } from "./components/Link";

function Areas() {
  const { businessType } = useBusinessTypeContext();
  return (
    <div>
      <p>{data.get(businessType).texts.areasIntro}</p>
      <p>
        En este caso, probablemente necesitemos organizar a los empleados en
        areas y categorias como:
      </p>
      <div style={{ display: "flex" }}>
        <div
          style={{
            backgroundColor: "#e7e8ed",
            padding: "1rem 2rem 1rem 1rem",
            margin: "1rem 0",
            borderRadius: "5px",
            width: "auto",
          }}
        >
          <ul style={{ margin: "0" }}>
            {data
              .get(businessType)
              ?.areasAndCategories.map(({ name, categories }) => (
                <li style={{ marginBottom: "1rem" }}>
                  {name}
                  <ul>
                    {categories.map((category) => (
                      <li>{category}</li>
                    ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <h3>Crear areas y categorias</h3>
      <p>
        Esta sera una acción que solo el dueño de la organización podra
        realizar. Debera dirigirse a la sección de configuración en el{" "}
        <b>dashboard</b>:
      </p>
      <DashboardItem
        active
        text="Configuracion"
        Icon={GearFill}
        to={"dashboard/settings"}
      />
      <p>
        Dentro de otras opciones se encontrara la de <b>areas</b> de la
        siguiente manera
      </p>
      <Image src={AreasTableInit} />
      <p>
        Con el botón de{" "}
        <Button size="sm" variant="success">
          Nueva area
        </Button>{" "}
        se abrira un formulario donde debera introducir el nombre del área que
        quiere crear, por ejemplo:{" "}
        <b>{data.get(businessType)?.areasAndCategories[0].name}</b>
      </p>
      <Image width="50%" src={AreaCreate} />
      <h3>Crear categorias</h3>
      <p>
        Para crear las categorias de un area, por ejemplo{" "}
        <b>
          {data.get(businessType)?.areasAndCategories[0].categories.join(", ")}
        </b>{" "}
        para el área <b>{data.get(businessType)?.areasAndCategories[0].name}</b>
        , se debe presionar el botón de editar{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>{" "}
        y se abrira un formulario para poder crear/eliminar categorias, así como
        editar el nombre del área en cuestion
      </p>
      <Image width="50%" src={AreaEdit} />
      <p>Una vez creadas las categorias se veran asi:</p>
      <Image width="50%" src={AreaEdited} />
      <h3>Eliminar o desactivar areas</h3>
      <p>
        Para eliminar un area, debera presionar el botón de{" "}
        <Button size="sm" variant="danger">
          <Trash />
        </Button>{" "}
        en la tabla.
      </p>
      <p>
        Esto abrira una alerta de confirmacion, que en caso que el área no tenga
        ningún empleado asignado sera de <b>eliminacion</b>
      </p>
      <Image width="50%" src={AreaDelete} />
      <p>
        Pero en caso que si existan empleados en el area, solo se podra{" "}
        <b>desactivar</b> el area, es decir, no se podra asignar a nuevos
        empleados pero no se perdera el registro de los empleados que ya esten
        asignados al area.
      </p>
      <Image width="50%" src={AreaDisable} />
      <h3>Asignar empleados a un area/categoria</h3>
      <p>
        Para asignar un empleado a un area, sera necesario editarlo desde su
        página de detalle, explicado en la sección de{" "}
        <Link href="Empleados">Empleados</Link>.
      </p>
      <Image src={AreaEmployee} />
      <h3>Beneficios de las areas y categorias</h3>
      <p>
        Tener a la organización separada en areas permite un mejor control en
        muchos aspectos.
      </p>
      <p>
        - Permite la creación de usuarios administradores capaces de controlar y
        visualizar únicamente el área que tenga asignada (explicado en{" "}
        <Link href="Administradores">Administradores</Link>)
      </p>
      <p>
        - Tambien facilita la generación de informes de anomalias y detección de
        faltas o llegadas tarde (explicado en{" "}
        <Link href="Anomalias%20y%20analisis%20de%20fichadas">Anomalias</Link>)
      </p>
      <p>
        - Pero su principal uso es en la gestión de turnos y las asignaciones de
        estos (explicado en <Link href="Turnos">Turnos</Link>)
      </p>
    </div>
  );
}

export { Areas };
