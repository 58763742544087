import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import UserContextProvider from "../context/UserContext";
import { sessionService } from "../services/session";

export default function useUser() {
  const { session, setSession } = useContext(UserContextProvider);
  const [state, setState] = useState({ loading: false, error: false });
  const navigate = useNavigate();

  const login = useCallback(
    ({ username, password }) => {
      setState({ loading: true, error: false });
      sessionService
        .login({ username, password })
        .then((userData) => {
          window.localStorage.setItem("token", userData.payload.token);
          setState({ loading: false, error: false });
          setSession(userData.payload);
        })
        .catch((err) => {
          window.localStorage.removeItem("token");
          setState({
            loading: false,
            error: true,
            verificationRequired: err.verification,
            message: err.message ?? "Se produjo un error",
          });
        });
    },
    [setSession]
  );

  // FIX: dependency array
  const register = useCallback(
    ({ username, password, name, lastname, org_name, phone, where }) => {
      setState({ loading: true, error: false });
      sessionService
        .register({
          username,
          password,
          name,
          lastname,
          org_name,
          phone,
          where,
        })
        .then(() => {
          setState({
            loading: false,
            error: false,
            verificationRequired: true,
          });
        })
        .catch((err) => {
          let inputs = {
            email: false,
            name: false,
            lastname: false,
            org_name: false,
            password: false,
          };
          if (err.status === 400) {
            Object.keys(inputs).map((v) => {
              if (err.message.includes(v)) {
                inputs[v] = true;
              }
              return v; //para que eslint no se queje :)
            });
          }

          setState({
            loading: false,
            error: true,
            message: err.message,
            inputError: inputs,
          });
        });
    },
    [login]
  );

  const checkSession = useCallback(() => {
    setState({ loading: true, error: false });
    const recoveredToken = window.localStorage.getItem("token");
    sessionService
      .checkSession({ token: recoveredToken })
      .then((userData) => {
        window.localStorage.setItem("token", userData.token);
        setState({ loading: false, error: false });
        setSession(userData);
      })
      .catch((err) => {
        window.localStorage.removeItem("token");
        setState({ loading: false, error: true, message: err.message });
      });
  }, [setSession]);

  const refreshUserData = useCallback(
    ({ token }) => {
      setState({ loading: true, error: false });
      sessionService
        .checkSession({ token })
        .then((userData) => {
          window.localStorage.setItem("token", userData.payload.token);
          setState({ loading: false, error: false });
          setSession(userData.payload);
        })
        .catch((err) => {
          window.localStorage.removeItem("token");
          setState({ loading: false, error: true, message: err.message });
        });
    },
    [setSession]
  );

  // FIX: dependency array
  const chgPassword = useCallback(
    ({ currentPassword, newPassword }) => {
      setState({ loading: true, error: false });

      sessionService
        .chgPassword({
          token: session.token,
          currentPassword,
          newPassword,
        })
        .then(() => {
          setState({ loading: false, error: false, passwordChanged: true });
        })
        .catch((err) => {
          setState({ loading: false, error: true, message: err.message });
        });
    },
    [setSession]
  );

  const finishTour = useCallback(
    ({ type }) => {
      setState({ loading: true, error: false });

      sessionService
        .finishTour({
          token: session.token,
          type,
        })
        .then(() => {
          setSession((prev) => ({ ...prev, tour_finished: type }));
          setState({ loading: false, error: false });
        })
        .catch((err) => {
          setState({ loading: false, error: true, message: err.message });
        });
    },
    [setSession]
  );

  const logout = useCallback(() => {
    window.localStorage.removeItem("token");
    navigate("/");
    setSession({});
  }, [setSession, navigate]);

  const validatePermissions = useCallback(
    ({ scopesNeeded = [] }) => {
      if (!session.token) return false;
      const userScopes = jwt(session.token).scopes;
      let authorized = true;

      if (userScopes.indexOf("owner") !== -1) return authorized; //reviso si es dueño de la organizacion

      scopesNeeded.forEach((needed) => {
        if (userScopes.indexOf(needed) === -1) authorized = false;
      });

      return authorized;
    },
    [session]
  );

  return {
    passwordChanged: state.passwordChanged,
    sessionData: session,
    isLogged: Boolean(session.token),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    loginMessage: state.message,
    verificationRequired: state.verificationRequired,
    registerErrorInput: state.inputError ?? {
      email: false,
      name: false,
      lastname: false,
      org_name: false,
      password: false,
    },
    login,
    logout,
    register,
    refreshUserData,
    checkSession,
    validatePermissions,
    chgPassword,
    finishTour,
  };
}
