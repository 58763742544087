import { useCallback, useState } from "react";
import { paymentsService } from "../services/payments";

export default function usePayments() {
  const [state, setState] = useState({
    loading: false,
    data: [],
    error: false,
    errorMessage: null,
  });

  // FIX: dependency array
  const getTiers = useCallback(() => {
    setState({ loading: true, data: [], error: false, errorMessage: null });

    paymentsService
      .getTiers()
      .then((data) => {
        setState({ loading: false, data, error: false, errorMessage: null });
      })
      .catch((err) => {
        setState({
          errorMessage: err.message,
          loading: false,
          error: true,
          data: [],
        });
      });
  }, [state]);

  const updatePlan = useCallback(
    ({ token, action, cb }) => {
      setState({ loading: true, data: [], error: false, errorMessage: null });

      paymentsService
        .updatePlan({ token, action })
        .then(() => {
          setState({
            ...state,
            loading: false,
            error: false,
            errorMessage: null,
          });
          cb();
        })
        .catch((err) => {
          setState({
            ...state,
            errorMessage: err.message,
            loading: false,
            error: true,
          });
        });
    },
    [state]
  );
  return {
    updatePlan,
    getTiers,
    ...state,
  };
}
