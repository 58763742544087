import { Image } from "./components/Image";
import { DashboardItem } from "./components/DashboardItem";
import { Button } from "react-bootstrap";
import {
  PencilSquare,
  EyeFill,
  BriefcaseFill,
  Trash,
} from "react-bootstrap-icons";
import ParticularShiftsTable from "./images/particular_shifts_table.png";
import ParticularShiftsCreateModal from "./images/particular_shifts_create_modal.png";

function ParticularShifts() {
  return (
    <>
      <p>
        Los turnos particulares permiten asignar un turno diferente para un dia
        en especifico. Esto puede ser útil cuando algún empleado, por ejemplo,
        llega tarde por una visita médica y se le permite recuperar horas. En
        este caso, se le podria asignar al empleado un turno particular con las
        horas correspondientes a su ingreso y egreso.
      </p>
      <p>
        Es importante tener en cuenta que los turnos particulares toman
        presedencia por sobre cualquier otro tipo de turno, inclusive los turnos
        prioritarios, sin importar el horario de los mismos. Es decir, si el
        empleado tiene un turno normal de 8:00 a 17:00 y un turno particular de
        18:00 a 21:00, el turno normal es completamente ignorado por Praesenti a
        pesar de ser horarios que no se superponen.
      </p>
      <h3>Creando un turno particular</h3>
      <DashboardItem
        active
        text="Empleados"
        Icon={BriefcaseFill}
        to={"dashboard/employees"}
      />
      <p>
        Luego dirigirse al detalle del empleado al que se le quiera agregar un
        turno particular. Para ir al detalle del empleado presione el siguiente
        boton:{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#3c4b64", borderColor: "#3c4b64" }}
        >
          <EyeFill />
        </Button>
      </p>

      <p>
        Dentro de los detalles del empleado, debajo de los datos y las fichadas
        del mismo, se encuentra el desplegable de turnos particulares.
      </p>
      <Image src={ParticularShiftsTable} />
      <p>
        Al tocar el boton{" "}
        <Button size="sm" variant="success">
          Nuevo turno particular
        </Button>{" "}
        se abrira el siguiente formulario:
      </p>
      <Image src={ParticularShiftsCreateModal} />
      <p>
        Una vez creado el turno particular, los siguientes botones de la tabla
        de turnos particulares permitiran editar y eliminar los mismos.{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>{" "}
        <Button size="sm" variant="danger">
          <Trash />
        </Button>{" "}
      </p>
    </>
  );
}

export { ParticularShifts };
