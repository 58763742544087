import "./Select.css";

const Select = ({ label, value, options, setValue, disabled, name }) => {
  return (
    <div className="select__container">
      <label className="select__label">{label}:</label>
      <select
        disabled={disabled}
        className="select__select"
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((v) => (
          <option value={v.id}>{v.name}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
