import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import useUser from "../hooks/useUser";
import Button from "../shared/components/Button";
import Header from "../shared/components/Header";
import { Link } from "react-router-dom";
import PaymentPausedImage from "../shared/assets/images/128.webp";
import "bootstrap/dist/css/bootstrap.min.css";

export default function PausedPage() {
  const navigate = useNavigate();

  const { isLogged } = useUser();

  useEffect(() => {
    if (!isLogged) {
      navigate(`/pricing`);
      return;
    }
  }, [isLogged, navigate]);

  return (
    <>
      <Header />
      <Container className="centered_section centered_section-404">
        <Row className="error404__section">
          <Col>
            <img
              className="error404__image"
              src={PaymentPausedImage}
              alt="payment paused"
            />
          </Col>
          <Col>
            <h2 className="error404__title">Tu plan esta pausado!</h2>
            <p
              style={{
                fontSize: "1.5rem",
                margin: "2rem 0",
                textAlign: "center",
              }}
            >
              Debes reanudar tu plan desde Mercado Pago para poder continuar.
            </p>

            <Link to="/">
              <Button filled={true} text="Volver al inicio" large={true} />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
