import React from "react";
import "./Header.css";
import Logo from "../assets/images/logo.webp";
import Button from "./Button";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import useUser from "../../hooks/useUser";

function Header() {
  const { isLogged, logout } = useUser();

  return (
    <>
      <div className="header">
        <div className="header__container">
          <Link to="/">
            <img src={Logo} className="header__image" alt="Logo" />
          </Link>

          <div className="header__button-container">
            <Link to="/pricing" className="header__button header__link">
              <Button
                style={{ border: "none" }}
                filled={false}
                text="Precios"
              />
            </Link>
            <Link to="/docs/#" className="header__button header__link">
              <Button
                style={{ border: "none" }}
                filled={false}
                text="Documentación"
              />
            </Link>
            <div className="header__button-separator" />
            {!isLogged ? (
              <>
                <Link to="/login" className="header__button">
                  <Button filled={false} text="Iniciar Sesion" />
                </Link>
                <div className="header__button-separator" />
                <Link to="/register" className="header__button">
                  <Button filled={true} text="Comenzar" />
                </Link>
              </>
            ) : (
              <>
                <Button onClick={logout} filled={false} text="Cerrar Sesion" />
                <div className="header__button-separator" />
                <Link to="/dashboard" className="header__button">
                  <Button filled={true} text="Ir al Dashboard" />
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="header__separator"></div>
    </>
  );
}

export default Header;
