import { Alert } from "react-bootstrap";

const ErrorAlert = ({
  error,
  setError,
  dismissible = true,
  title = "Error",
}) => {
  return (
    <>
      {error && (
        <Alert
          dismissible={dismissible}
          onClose={() => setError(null)}
          variant={"danger"}
        >
          <b>{title}:</b> <br />
          {error}
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;
