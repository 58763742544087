import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { qrService } from "../services/qr";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useDevices from "../hooks/useDevices";
import "./shared.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "./assets/images/logo.webp";
import QRCode from "react-qr-code";
import { Alert, ProgressBar, Spinner } from "react-bootstrap";
import { Circle, CircleFill } from "react-bootstrap-icons";

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }

const QrPage = () => {
  const { height, width } = useWindowDimensions();
  const { getDeviceID } = useDevices();
  const navigate = useNavigate();
  const [qr, setQr] = useState();
  const [countdown, setCountdown] = useState();
  const [loading, setLoading] = useState();
  const [qrCounter, setQrCounter] = useState(0);
  const [qrSize, setQrSize] = useState(256);
  const [heartbeat, setHeartbeat] = useState(false);
  const [err, setErr] = useState();
  const [progressBar, setProgressBar] = useState(100);
  const progressRef = useRef(null);
  const intervalRef = useRef(null);

  //FIX: dependency array requires updateQR but function runs every rerender
  useEffect(() => {
    updateQR();
    intervalRef.current = setInterval(updateQR, 15500);
  }, []);

  useEffect(() => {
    setInterval(() => {
      setHeartbeat((prev) => !prev);
    }, 1000);
  }, []);

  useEffect(() => {
    const w = width - width * 0.1;
    const h = height - height * 0.3 - 50;

    if (w > h) return setQrSize(h);
    setQrSize(w);
  }, [width, height]);

  function updateQR() {
    setQrCounter((prev) => prev + 1);
    setLoading(true);
    setCountdown(15);
    clearInterval(progressRef.current);
    setProgressBar(100);
    qrService
      .getNewQR({ device_id: getDeviceID() })
      .then(({ payload }) => {
        setErr(false);
        setLoading(false);
        setQr(payload.qr);
        progressRef.current = setInterval(() => {
          setCountdown((prev) => prev - 1);
          setProgressBar((prev) => {
            return prev - 6.66;
          });
        }, 1000);
      })
      .catch(({ statusCode, message }) => {
        setLoading(false);
        if (statusCode === 401) {
          clearInterval(intervalRef.current);
          navigate("/");
          return;
        }
        setErr(message);
      });
  }

  return (
    <section className="centered_section">
      <img
        src={Logo}
        style={{ width: `${qrSize}px`, marginBottom: "2vh" }}
        alt="Logo"
      />
      {err ? (
        <Alert style={{ width: "80%" }} variant="danger">
          <b>Error:</b> <br />
          {err}
        </Alert>
      ) : (
        <div>
          {qr ? (
            <>
              <QRCode
                fgColor="#2d2d2d"
                bgColor="#f4f5fa"
                size={qrSize}
                value={qr}
              />
              {loading ? (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    right: "0",
                    // background: "red",
                    background: "#ffffff75",
                  }}
                ></div>
              ) : null}
            </>
          ) : (
            <Spinner
              style={{ width: "10vh", height: "10vh", borderWidth: "0.5em" }}
              animation="border"
              size="lg"
            />
          )}
          <ProgressBar
            style={{ minWidth: "80%", marginTop: "2vh" }}
            animated
            now={progressBar}
          />
          <div>{countdown}s</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: 10,
              right: 10,
            }}
          >
            {qrCounter}
            {heartbeat ? <Circle /> : <CircleFill />}
          </div>
        </div>
      )}
    </section>
  );
};

export default QrPage;
