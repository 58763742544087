import { Image } from "./components/Image";
import { DashboardItem } from "./components/DashboardItem";
import { PeopleFill, PencilSquare } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import AdminsEditModal from "./images/admins_new_form.png";
import AdminsExampleModal from "./images/admins_example_modal.png";
import AdminsTable from "./images/admins_table.png";

function Admins() {
  return (
    <>
      <p>
        En Praesenti los administradores pueden tener las mismas
        capacidades/permisos que el dueño de la empresa o puede estar
        restringido a solo un par de capacidades.
      </p>
      <p>
        Para configurar los administradores dirigase a la sección de
        "Administradores" en el <b>Dashboard</b>:
      </p>
      <DashboardItem
        active
        text="Administradores"
        Icon={PeopleFill}
        to={"dashboard/admins"}
      />
      <Image src={AdminsTable} />

      <h3>Creando un administrador</h3>
      <p>
        Al tocar el boton{" "}
        <Button size="sm" variant="success">
          Nuevo adminstrador
        </Button>{" "}
        se abrira el siguiente formulario:
      </p>
      <Image src={AdminsEditModal} />
      <p>
        El campo "Area" es opcional, esto significa que si un administrador es
        parte de un area, sus acciones seran restringidas al área a la que
        pertenece. Si un administrador no es parte de un area, sus acciones
        seran validas a lo largo de toda la empresa al igual que el dueño.
      </p>

      <p>
        Luego de eso estan los permisos. Esta es la parte más importante de los
        administradores, Aqui es donde se limitan las capacidades de un
        administrador.
      </p>

      <h3>Editando un administrador</h3>
      <p>
        El boton{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>{" "}
        nos permite editar un administrador.
      </p>
      <p>
        En el caso de que querramos que un Administrador solamente pueda crear,
        modificar empleados debemos seleccionar las siguientes casillas dentro
        del apartado de permisos:
      </p>

      <Image src={AdminsExampleModal} />
    </>
  );
}

export { Admins };
