import { Link } from "./components/Link";
import { DashboardItem } from "./components/DashboardItem";
import {
  BriefcaseFill,
  EyeFill,
  UpcScan,
  Trash,
  PencilSquare,
} from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { Image } from "./components/Image";
import EmployeeTable from "./images/employee_table.png";
import EmployeeCreateModal from "./images/employee_create_modal.png";
import EmployeeDetail from "./images/employee_detail.png";
import EmployeeQr from "./images/employee_qr.png";
import EmployeeEdit from "./images/employee_edit.png";

function Employees() {
  return (
    <>
      <p>
        La sección de Empleados contiene un botón para crear nuevos empleados y
        una tabla con todos los empleados desde donde se permite ver, editar o
        eliminar empleados.
      </p>
      <DashboardItem
        active
        text="Empleados"
        Icon={BriefcaseFill}
        to={"dashboard/employees"}
      />
      <p>
        Al principio la tabla se vera así ya que no hay ningún empleado
        registrado:
      </p>
      <Image src={EmployeeTable} />
      <h2>Creando un empleado</h2>
      <p>
        Para crear un empleado debemos tocar el boton{" "}
        <Button size="sm" variant="success">
          Nuevo empleado
        </Button>
        . Esto abrira un formulario con los siguientes campos:
      </p>
      <Image width="50%" src={EmployeeCreateModal} />
      <p>
        Los campos de Area y Categoria se veran con más detalle en los
        siguientes <Link href="Areas%20y%20categorias">capitulos</Link>, por el
        momento utilicemos los que vienen por defecto.
      </p>
      <p>
        Volviendo a la tabla, ahora tendremos un empleado nuevo con los datos
        que hayamos ingresado en el formulario. A la derecha de la fila en donde
        se encuentra ubicado el empleado en la tabla, podremos encontrar los 3
        botones de acción sobre ese empleado en particular.
      </p>
      <h3>Detalle del empleado</h3>
      <p>
        El{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#3c4b64", borderColor: "#3c4b64" }}
        >
          <EyeFill />
        </Button>{" "}
        permite ver los datos del empleado
      </p>
      <Image src={EmployeeDetail} />
      <p>
        Tambien se podran encontrar los botones de{" "}
        <Button size="sm">Editar</Button> y{" "}
        <Button size="sm" variant="danger">
          Eliminar
        </Button>{" "}
        (los cuales cumplen con la misma función que los botones{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>{" "}
        y{" "}
        <Button size="sm" variant="danger">
          <Trash />
        </Button>{" "}
        en la tabla) conjunto con el botón de{" "}
        <Button size="sm" variant="dark">
          Obtener QR &nbsp;
          <UpcScan />
        </Button>
        . Con este último botón se podra asociar el perfil del empleado con el
        teléfono del empleado.
      </p>
      <p>
        Debajo de los datos del cliente estaran los desplegables de fichadas
        ingreso-egreso, turnos particulares y registro de bajas. Para mas
        detalle sobre estos desplegables dirigirse a{" "}
        <Link href="Fichadas">Fichadas</Link>,<Link href="Turnos">Turnos</Link>{" "}
        o <Link href="Turnos%20particulares">Turnos particulares</Link>
      </p>

      <h3>Habilitando un empleado</h3>
      <p>
        Para que los empleados puedan realizar fichadas de ingreso y egreso al
        lugar de trabajo se precisa que sus telefonos, con la aplicación de
        Praesenti previamente instalada, esten asociados a la empresa.
      </p>
      <p>
        El boton{" "}
        <Button size="sm" variant="dark">
          Obtener QR &nbsp;
          <UpcScan />
        </Button>{" "}
        nos permite hacer exactamente eso. Al apretarlo aparecera un código QR
        el cual debera ser leído por la aplicación de Praesenti del empleado
        cuyo teléfono se quiera asociar.
      </p>

      <Image width="40%" src={EmployeeQr} />
      <p>
        (Tengase en cuenta que el QR solamente es válido por 15 segundos y que
        si no llega a ser utilizado en tiempo se debera generar uno nuevo
        volviendo a apretar el mismo boton. Se recomienda que al hacer este paso
        se este con el empleado y el teléfono que se desee vincular)
      </p>

      <h3>Editando un empleado</h3>
      <p>
        El{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>{" "}
        nos permite editar un empleado. Al tocarlo veremos el detalle del
        empleado y tendremos la posibilidad de ed los campos. Si se desean hacer
        cambios, simplemente hay que cambiar los campos necesarios y darle al
        botón de guardar.
      </p>
      <Image src={EmployeeEdit} />
      <h3>Eliminando un empleado</h3>
      <p>
        El{" "}
        <Button size="sm" variant="danger">
          <Trash />
        </Button>{" "}
        nos permite eliminar o desactivar el empleado en cuestion.
      </p>
      <p>
        En caso de que el empleado <b>no tenga</b> un historial de fichadas,
        aparecera en pantalla una alerta preguntando si realmente quiere borrar
        el empleado de manera permanente.
      </p>
      <p>
        En el caso de que el empleado <b>si tenga</b> un historial de fichadas,
        aparecera en pantalla una alerta avisando que, al el empleado tener un
        historial de fichadas no podra ser eliminado permanentemente hasta que
        se borren todas sus fichadas. En vez de borrarlo, el empleado se
        desactivara, esto significa que el empleado no podra realizar nuevas
        fichadas, ni podra ser editado por administradores, pero sus fichadas
        permaneceran intactas.
      </p>
    </>
  );
}

export { Employees };
