import { Button } from "react-bootstrap";
import { BoxArrowUpRight, Trash, UpcScan } from "react-bootstrap-icons";
import { DashboardItem } from "./components/DashboardItem";
import { Image } from "./components/Image";
import DevicesCreate from "./images/devices_create.png";
import DevicesDelete from "./images/devices_delete.png";
import DevicesQR from "./images/devices_qr.png";
import DevicesTable from "./images/devices_table.png";
import CustomButton from "../shared/components/Button";

function Devices() {
  return (
    <div>
      <p>
        Los dispositivos seran los encargados de mostrar los QRs que usaran los
        empleados mediante la app para registrar su ingreso o egreso. Pueden ser
        desde una tablet, un celular o una computadora.
      </p>
      <p>
        Estos deberan estar permanente (o durante el período laboral) conectados
        a internet, y cada 15 segundos mostraran un código diferente. Esto para
        evitar que los empleados puedan compartir el código a alguien fuera del
        establecimiento, haciendo que el corto tiempo de vida de este complique
        dicha accion.
      </p>

      <p>
        Para configurar los dispositivos debera dirigirse a la sección en el{" "}
        <b>Dashboard</b>.
      </p>
      <DashboardItem
        active
        text="Dispositivos"
        Icon={UpcScan}
        to={"dashboard/qr"}
      />

      <p>
        Inicialmente aparecera vacío ya que no existe ningún dispositivo
        permitido
      </p>
      <Image src={DevicesTable} />

      <h3>Permitir dispositivos</h3>
      <h5>
        * Esto debe llevarse a cabo desde el dispositivo que se quiera permitir,
        previamente iniciado sesion!
      </h5>
      <p>
        Para permitir que un dispositivo acceda a la creación de QRs temporales
        debe presionar el botón de{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#4c399f", borderColor: "#4c399f" }}
          filled="true"
        >
          Permitir este dispositivo
        </Button>
        . Esto abrira el siguiente formulario:
      </p>
      <Image width="50%" src={DevicesCreate} />

      <p>
        Una vez permitido, para que el dispositivo empiece a mostrar los QRs
        solo debe presionar el botón de
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <CustomButton>
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BoxArrowUpRight />
            <span style={{ marginLeft: "0.5rem" }}>Pantalla de QR</span>
          </span>
        </CustomButton>
      </div>

      <p>
        Esto cerrara su sesion, para evitar que algún empleado pueda acceder a
        Praesenti desde el dispositivo en cuestion, ya que recordemos, este debe
        estar al alcance de los empleados para que puedan utilizar el QR
        mostrado en pantalla para marcar su ingreso o egreso desde la aplicacion
      </p>

      <h3>Pantalla de QRs</h3>
      <p>
        Desde los dispositivos permitidos, se podra acceder a{" "}
        <a href="https://praesenti.com.ar">praesenti.com.ar/qr</a>, desde donde
        automaticamente cada 15 segundos se generara un nuevo código QR. La
        pantalla se vera de la siguiente manera:
      </p>
      <Image width="40%" src={DevicesQR} />

      <h3>Eliminar dispositivos</h3>
      <p>
        En caso que deseara eliminar el acceso de un dispositivo a la creacion
        de QRs solo debe presionar en el botón de{" "}
        <Button size="sm" variant="danger">
          <Trash />
        </Button>{" "}
        en la tabla de dispositivos. Lo que abrira una alerta de confirmacion
        como la siguiente
      </p>
      <Image width="50%" src={DevicesDelete} />
      <p>
        Una vez eliminado, este dispositivo no podra solicitar la creación de
        nuevos QRs
      </p>
    </div>
  );
}

export { Devices };
