import { useState } from "react";

function Image({ src, width, height, alt }) {
  const [opened, setOpened] = useState(false);

  window.addEventListener("scroll", (e) => setOpened(false));

  return (
    <>
      <div
        onClick={() => setOpened((prev) => !prev)}
        className={`docs__image-container ${opened ? "opened" : ""}`}
      >
        <img
          style={opened ? {} : { width, height }}
          className={`docs__image ${opened ? "opened" : ""}`}
          alt={alt || "Imagen de ejemplo"}
          src={src}
        />
      </div>
    </>
  );
}

export { Image };
