import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../shared/components/Header";
import Footer from "../shared/components/Footer";
import useUser from "../hooks/useUser";
import SuccesImage from "../shared/assets/images/payment_success.webp";
import ErrorImage from "../shared/assets/images/payment_error.webp";
import PendingImage from "../shared/assets/images/payment_pending.webp";
import config from "../config";
import axios from "axios";
import Button from "../shared/components/Button";
import { Link } from "react-router-dom";

async function getPreapproval({ externalReference, token }) {
  const url = `${config.ip}/api/payments/${externalReference}`;
  try {
    const { data: body } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return body.data;
  } catch (err) {
    console.error(err);
  }
}

const Image = ({ status }) => {
  switch (status) {
    case "authorized":
      return (
        <img
          alt="Pago realizado"
          src={SuccesImage}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
        />
      );
    case "pending":
    case "created":
      return (
        <img
          alt="Pago pendiente"
          src={PendingImage}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
        />
      );
    default:
      return (
        <img
          alt="Error en pago"
          src={ErrorImage}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
        />
      );
  }
};

const Text = ({ status }) => {
  // orgPreapproval.status =
  // "authorized" | "cancelled" | "pending" | "paused" | "created" | "undefined"
  //
  // Auth: actualizar + mensaje + redirect false
  // Crea: actualizar + redirect
  // Pend: actualizar + redirect true
  // Paus: nada + mensaje de pausado + redirect false
  // Canc: new + redirect true
  // None: new + redirect
  switch (status) {
    case "authorized":
      return (
        <>
          <h1>El pago ha sido realizado con exito</h1>
          <p>Muchas gracias!</p>
        </>
      );
    case "pending":
    case "created":
      return (
        <>
          <h1>El pago esta siendo procesado</h1>
          <p>Esto puede llevar unos minutos...</p>
        </>
      );
    case "paused":
      return (
        <>
          <h1>La suscripción esta pausada!</h1>
          <p>
            Debe reanudar la suscripción desde mercado pago para poder
            continuar.
          </p>
        </>
      );
    case "cancelled":
      return (
        <>
          <h1>La suscripción esta cancelada!</h1>
          <p>
            Contactese con help@praesenti.com.ar para reanudarla y continuar
            utilizando praesenti.
          </p>
        </>
      );

    default:
      return <h1>Oops, este pago no existe!</h1>;
  }
};

export default function Backurl() {
  const navigate = useNavigate();
  const { externalReference } = useParams();
  const { isLogged, sessionData } = useUser();
  const [status, statusSet] = useState("created");

  useEffect(async () => {
    if (!isLogged) {
      navigate(`/login?redirect=/payments/backurl/${externalReference}`);
      return;
    }

    const preapproval = await getPreapproval({
      externalReference,
      token: sessionData.token,
    });

    if (!preapproval) {
      navigate("/");
      return;
    }

    statusSet(preapproval.status);
  }, [isLogged, navigate, sessionData.token, externalReference]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col xs={4}>
            <Image status={status} />
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text status={status} />
            <div style={{ margin: "2rem 0" }}>
              <Link to="/">
                <Button filled={true} text="Volver al inicio" large={true} />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
