import { Image } from "./components/Image";
import { DashboardItem } from "./components/DashboardItem";
import { Button } from "react-bootstrap";
import {
  ExclamationTriangleFill,
  EyeFill,
  PencilFill,
  Check,
  GearFill,
  CheckSquareFill,
} from "react-bootstrap-icons";
import AnomaliesTable from "./images/anomalies_table.png";
import AnomaliesTableFill from "./images/anomalies_table_fill.png";
import AnomaliesTableDetail from "./images/anomalies_table_detail.png";
import AnomaliesJustification from "./images/anomalies_justification.png";
import AnomaliesJustificationCollapsable from "./images/justifications_collapse.png";

function Anomalies() {
  return (
    <>
      <p>
        La sección "control de fichadas" muestra todas la anomalias (por
        ejemplo, llegadas tarde o faltas) dentro de un período de tiempo.
        Opcionalmente podemos aclarar el área en específico que querramos
        buscar.
      </p>
      <DashboardItem
        active
        text="Control de fichadas"
        Icon={ExclamationTriangleFill}
        to={"dashboard/anomalies"}
      />
      <Image src={AnomaliesTable} />
      <p>
        Una vez seleccionados los campos de Desde y Hasta apareceran todas las
        anomalias en ese rango de tiempo. Tengase en cuenta que existe la
        posiblidad de que no haya ninguna anomalia.
      </p>
      <Image src={AnomaliesTableFill} />

      <p>
        La tabla muestra día por dia, dentro del rango temporal que se busco, la
        cantidad de empleados esperados al lugar de trabajo y cuantos realmente
        fueron.
      </p>
      <p>
        La columna de estado muestra si hubo alguna falta o llegada tarde,
        dependiendo del color del signo de exclamación que haya. Si este es
        amarillo hubo llegadas tarde, pero si este es rojo significa que hubo
        faltas.
      </p>
      <p>
        Por último la columna de justificados muestra si alguna de las llegadas
        tarde o las faltas ha sido justificada o no.
      </p>
      <p>
        Para más detalles sobre un día en particular toque el boton{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#3c4b64", borderColor: "#3c4b64" }}
        >
          <EyeFill />
        </Button>
        .
      </p>

      <Image src={AnomaliesTableDetail} />
      <p>
        Esta tabla muestra todas los empleados que tendiran que haber asistido.
        Opcionalmente se puede filtrar la tabla por area.
      </p>
      <ul>
        <li>
          Turno correcto:{" "}
          <CheckSquareFill size={20} style={{ color: "#198754" }} />
        </li>
        <li>
          Llegada tarde:{" "}
          <ExclamationTriangleFill size={20} style={{ color: "orange" }} />
        </li>
        <li>
          Falta:{" "}
          <ExclamationTriangleFill size={20} style={{ color: "#bd3131" }} />
        </li>
        <li>
          Justificado: <Check size={24} style={{ color: "#198754" }} />
        </li>
        <li>
          Franco: <Check size={24} style={{ color: "#0d6efd" }} />
        </li>
      </ul>

      <p>
        En el caso de que se quiera justificar una falta o una llegada tarde se
        debe crear primero un motivo de justificacion. Para hacer eso hay que ir
        a la sección de Configuracion en el <b>Dashboard</b>:
      </p>
      <p>
        El botón <PencilFill /> en la columna "Justificacion" permite
        seleccionar alguna de las justificaciones previamente creadas en la
        sección de "Configuracion".
      </p>

      <h3>Creando una justificacion</h3>
      <p>
        Dirigase a la sección de "Configuracion" apretando el siguiente botón el
        la sección del <b>Dashboard</b>:
      </p>
      <DashboardItem
        active
        text="Configuracion"
        Icon={GearFill}
        to={"dashboard/settings"}
      />

      <p>
        Justo debajo de los datos de la organizacion, el plan de subscripción de
        Praesenti en el que la organización esta inscripto y las areas, se
        encuentra el menú desplegable de justificaciones.
      </p>
      <Image src={AnomaliesJustificationCollapsable} />
      <p>
        Al apretar el boton{" "}
        <Button size="sm" variant="success">
          Nuevo empleado
        </Button>{" "}
        se abrira un formulario solicitandole la razón de la justificacion.
      </p>

      <h3>Asignando la justificacion</h3>
      <p>
        Una vez creada la justificacion, podemos utilizarla con el boton{" "}
        <PencilFill /> para justificar una anomalia.
      </p>

      <Image src={AnomaliesJustification} width="50%" />
    </>
  );
}

export { Anomalies };
