import React, { useEffect, useState } from "react";
import { sessionService } from "../services/session";

const Context = React.createContext({});

export function UserContextProvider({ children }) {
  const token = window.localStorage.getItem("token");
  const [session, setSession] = useState({ token });

  // FIX: dependency array
  useEffect(() => {
    if (token && !session.email) {
      sessionService
        .checkSession({ token })
        .then(({ payload }) => {
          setSession(payload);
          window.localStorage.setItem("token", payload.token);
        })
        .catch(() => {
          setSession({});
          window.localStorage.removeItem("token");
        });
    }
  }, []);

  return (
    <Context.Provider
      value={{
        setSession,
        session,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
