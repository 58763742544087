import Header from "../shared/components/Header";
import Footer from "./components/Footer";
import { Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header />
      <Container>
        <div className="terms__container">
          <h1 style={{ marginBottom: "2rem", fontWeight: "700" }}>
            Terminos y condiciones de la Web y Servicios.
          </h1>

          <section style={{ marginBottom: "2rem" }}>
            <h5>Aviso Muy Importante: </h5>
            <p style={{ textAlign: "justify" }}>
              Se recomienda la total y detenida lectura del presente enlace, de
              inicio a fin, sin salteos o secciones sin leer. Ante cualquier
              duda sobre el contenido de éste enlace o sobre el contenido de
              cualquier parte de <u>https://praesenti.com.ar/</u>, por favor
              ingrese su consulta en la plataforma. De acuerdo a la naturaleza
              de la consulta que nos realice, le responderemos, en los términos
              del presente documento.
            </p>
          </section>

          <section style={{ marginBottom: "2rem" }}>
            <h5>Acerca de praesenti </h5>
            <p style={{ textAlign: "justify" }}>
              <u>www.praesenti.com.ar</u> (en adelante, “praesenti” o “La Web”)
              es una plataforma web de control de ingreso y egreso de personas a
              lugares físicos pertenecientes a organizaciones empresariales,
              asociada a la marca comercial Praesenti. La plataforma y la marca
              se encuentran protegidas por las normas nacionales e
              internacionales de propiedad intelectual e industrial. Praesenti
              provee a quienes la navegan y eventualmente solicitan sus
              productos y servicios (en adelante, Cliente/s) de información
              adicional, como marco de referencia y orientación, con fines
              comerciales e informativos generales y particulares respecto a los
              productos y servicios que comercializa. La información contenida
              en Praesenti no debe considerarse y no es asesoramiento
              profesional. Praesenti informa sobre los servicios y productos que
              se ofrecen a través de la plataforma mediante contenido propio
              desarrollado, y proporcionando enlaces en los casos en que así lo
              considera conveniente, a las cuales se puede acceder de manera
              directa o indirecta, que complementan la información de manera
              profunda. Sin embargo, en caso de dudas, recomendamos realizar
              consultas específicas por las vías de contacto a disposición de
              quienes así lo precisen, en los términos expresados en el presente
              y en <u>praesenti.com.ar</u>. Las condiciones descritas en el
              presente determinan las condiciones en las que los servicios y
              productos eventualmente serán prestados a través de{" "}
              <u>praesenti.com.ar</u>. El uso de <u>praesenti.com.ar</u> implica
              la aceptación de las condiciones descritas en el presente, a todo
              efecto legal, por lo que reiteradamente se recomienda su total y
              detenida lectura. En caso de desacuerdo con los términos y
              condiciones de navegación o venta de productos y servicios de
              parte de Praesenti, el Cliente deberá abstenerse de utilizar la
              plataforma, dado que en tal supuesto no habrá consenso sobre los
              términos en que Praesenti informa y comercializa.
            </p>
          </section>

          <section style={{ marginBottom: "2rem" }}>
            <h5>
              Contenidos y Navegación en <u>praesenti.com.ar</u>
            </h5>
            <p style={{ textAlign: "justify" }}>
              Toda información y/o contacto recibido por <u>praesenti.com.ar</u>{" "}
              solicitando respuestas, consultas o la compra de productos o la
              prestación de servicios deberá cumplir con los requisitos de hecho
              de: legalidad, veracidad, y vigencia; y de derecho: que las
              respuestas, consultas y solicitudes de servicios o productos no
              transgredan la ley, en el más amplio sentido de su entendimiento,
              para que la respuesta y/o servicios y/o productos a ser
              comercializados a través de praesenti sean adecuados, siendo el
              Cliente responsable de las condiciones en que provee la
              información base de las respuestas, consultas y/o servicios y/o
              productos solicitados a praesenti. El Cliente autoriza a praesenti
              - en los términos legales y del presente- al archivo,
              procesamiento y utilización de los datos que provee. Del mismo
              modo, el Cliente debe proveer - para la realización de ciertos
              servicios o la compra de productos que son adecuadamente
              informados en la plataforma y/o por email, información y/o
              documentación complementaria (conjuntamente denominada Información
              en el presente). <br />
              Praesenti no utilizará la información provista por el Cliente para
              ninguna otra finalidad diferente a la i) venta de productos y/o
              prestación requerida por el Cliente, ii) contactar el Cliente vía
              electrónica, o iii) cuestiones relativas a la navegabilidad y
              utilización del Cliente de praesenti. En todo momento el Cliente
              podrá solicitar el cierre de su cuenta y la eliminación de sus
              datos de praesenti.
            </p>
          </section>

          <section style={{ marginBottom: "1rem" }}>
            <h5>Compra de productos y solicitudes de Servicios y Cuentas</h5>
            <p style={{ textAlign: "justify" }}>
              Ámbito de comercialización: La oferta de productos y servicios de
              Praesenti es apta para consumidores o usuarios de toda la
              Argentina. <br />
              Productos: los productos ofertados por Praesenti son única y
              exclusivamente los que pueden encontrarse en la plataforma, y se
              aplican únicamente los términos y condiciones aquí explicitados.
              Ninguna oferta de adquisición de productos o servicios que sea
              realizada a praesenti podrá considerarse aceptada tácitamente o
              fuera de los presentes términos y condiciones. <br />
              Métodos de pago: los usuarios podrán adquirir los productos y
              servicios ofrecidos por praesenti mediante MercadoPago y sus
              medios de pagos asociados. Los precios de productos y servicios
              son los vigentes al momento del pago del producto y servicio
              correspondiente, según lista de precios que se actualiza
              periódicamente. Los servicios comercializados por Praesenti son
              propios, en consecuencia las garantías legales correspondientes.
              <br />
              Los servicios son prestados mediante las modalidades y condiciones
              informadas en la web, en la oferta correspondiente.
              <br /> Praesenti provee por su intermedio o a través de terceros,
              según se indique en cada caso, el servicio de control de ingreso y
              egreso de personas a espacios físicos pertenecientes a cualquier
              tipo de organización que requiera controlar ingresos y egresos de
              personal. El servicio de soporte al cliente y de atención de
              reclamos es provisto mediantes comunicaciones establecidas por
              medios de contacto informados en la plataforma.
            </p>
          </section>

          <section style={{ marginBottom: "1rem" }}>
            <h5>Servicios: </h5>
            <p style={{ textAlign: "justify" }}>
              Praesenti no enviará, en ningún caso y bajo ninguna circunstancia,
              rechazos de servicios expresos. En consecuencia el Cliente conoce
              y acepta que en todo momento es facultativo y potestativo de
              Praesenti aceptar ofertas de adquisición de productos o servicios,
              y/o brindar respuestas o servicios, previo análisis interno que
              realice.
              <br /> La falta de respuesta al Cliente dentro del plazo informado
              por Praesenti para las consultas o cualquier solicitud realizada a
              Praesenti implican tácitamente el rechazo de la consulta o
              solicitud de servicio que hubiese realizado el Cliente.
            </p>
          </section>

          <section style={{ marginBottom: "1rem" }}>
            <h5>Responsabilidades e Indemnidades: </h5>
            <p style={{ textAlign: "justify" }}>
              El Cliente mantendrá indemne a Praesenti, su personal y
              profesionales que presten servicios a causa de toda acción u
              omisión del Cliente.
              <br /> El Cliente-Usuario es responsable ùnico de la utilización
              que le dé a los datos, respecto a la informaciòn relativa a
              control de ingresos y egresos informada mediante la utilización de
              la plataforma. Praesenti no posee relación directa o indirecta con
              las personas a las cuales el Cliente controlara los ingresos y
              egresos. El cliente declara bajo juramento que no utilizara la
              plataforma para realizar controles en condiciones que transgredan
              o puedan transgredir ninguna norma legal, municipal, provincial o
              nacional.
              <br /> El Cliente-Usuario se compromete a utilizar la información
              y la plataforma acorde a las regulaciones legales aplicables,
              siendo el cliente-usuario único responsable del adecuado uso.
            </p>
          </section>

          <section style={{ marginTop: "3rem" }}>
            <h3>Servicios. Especificaciones:</h3>
            <section style={{ marginBottom: "1rem" }}>
              <h5>Consultas: </h5>
              <p style={{ textAlign: "justify" }}>
                Praesenti posee habilitadas diversas vías de consulta. Al
                respecto, el Cliente asume y acepta que Praesenti no ofrece ni
                asume ninguna garantía expresa ni tácita de tiempo de respuesta
                o respuesta efectiva, conforme es informado en el presente.
              </p>
            </section>

            <section style={{ marginBottom: "1rem" }}>
              <h5>Uso indebido, violación a la ley:</h5>
              <p style={{ textAlign: "justify" }}>
                Quien navega o utiliza los servicios de Praesenti deberá
                realizarlo acorde a la buena fe y legalidad que debe regir en la
                Web. Se encuentra expresamente prohibido su uso abusivo, de mala
                fe, mal intencionado, la copia o reproducción no autorizada, o
                cualquier otra forma antijurídica o antifuncional de interacción
                con praesenti.
              </p>
            </section>

            <section style={{ marginBottom: "1rem" }}>
              <h5>Versiones de Términos y Condiciones. Modificaciones: </h5>
              <p style={{ textAlign: "justify" }}>
                El Cliente toma conocimiento que Praesenti revisa, analiza y
                modifica periódicamente el contenido de los Términos y
                Condiciones de la Web y Servicios y eventualmente el contenido y
                servicios de Praesenti como plataforma web, a fines de
                evolucionar y adaptarse acorde a los servicios, la experiencia,
                el mejor servicio al Cliente y su política comercial y
                profesional. Es conveniente que el Cliente revise la presente
                Sección con periodicidad y/o toda vez que solicite servicios, o
                de cualquier forma se contacte con el Praesenti. Las
                modificaciones se considerarán aprobadas si transcurrieren 30
                días desde que la misma se realizará, sin que el Cliente
                manifestare su desacuerdo.
                <br /> El Cliente podrá manifestar su desacuerdo mediante envío
                de mensaje por las vías habilitadas dentro de Praesenti . En tal
                caso se procederá inmediatamente a dar de baja al cliente,
                eliminando todo registro de las bases de datos de praesenti.
              </p>
            </section>

            <section style={{ marginBottom: "1rem" }}>
              <h5>Servicios de asistencia técnica </h5>
              <p style={{ textAlign: "justify" }}>
                Los servicios de asistencia técnica pueden requerir información
                complementaria. En tal caso, el cliente se compromete a proveer
                toda la información necesaria para que Praesenti pueda proveer
                sus servicios de manera efectiva y en los términos del presente.
              </p>
            </section>

            <section style={{ marginBottom: "1rem" }}>
              <h5>Actualizaciones a los Términos y Condiciones </h5>
              <p style={{ textAlign: "justify" }}>
                Los Terminos y Condiciones de <u>praesenti.com.ar</u> son
                actualizados habitualmente, por razones legales, comerciales,
                administrativas, regulatorias y económicas. Las condiciones de
                uso de la plataforma son las establecidas al momento de la
                realización de las transacciones correspondientes y la
                continuación de los servicios implica aceptación de las nuevas
                condiciones establecidas.
              </p>
            </section>

            <section style={{ marginBottom: "1rem" }}>
              <h5>
                Domicilio. Jurisdicción. Normas Supletorias. Prórroga de
                Competencia:
              </h5>
              <p style={{ textAlign: "justify" }}>
                Praesenti posee domicilio legal electrónico a través de las vía
                de contacto en la plataforma y sus servidores. El uso de la
                plataforma o la solicitud de servicios implica la aceptación de
                la competencia a la Jurisdicción que sea aplicable en virtud de
                las normas aquí establecidas. Toda cuestión relacionada con la
                navegación en <u>Praesenti.com.ar</u>, la información brindada,
                y las transacciones realizadas, no previstas dentro del presente
                documento, serán resueltas acorde a la legislación aplicable y a
                los usos y costumbres y buena fe comercial.
                <br /> En caso de duda o comentarios sobre el presente, por
                favor envíenos su mensaje. Con gusto le responderemos.
              </p>
            </section>
          </section>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
