import axios from "axios";
import config from "../config";

const baseUrl = `${config.ip}/api/qr`;

async function registerDevice({ token, device_id, alias = "" }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "post",
      url: `${baseUrl}`,
      data: { device_id, alias },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          resolve(data);
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "Sin conexion" });
        switch (err.response.status) {
          case 401:
            reject({ error: true, message: "Sesion invalida" });
            break;

          case 403:
            reject({ error: true, message: "Permisos insuficientes" });
            break;

          case 409:
            reject({
              error: true,
              message: "Este dispositivo ya se encuentra registrado",
            });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function deleteDevice({ token, device_id }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "delete",
      url: `${baseUrl}/${device_id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          resolve(data);
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "Sin conexion" });
        switch (err.response.status) {
          case 401:
            reject({ error: true, message: "Sesion invalida" });
            break;

          case 403:
            reject({ error: true, message: "Permisos insuficientes" });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function getDevices({ token }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "get",
      url: `${baseUrl}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          resolve(data.devices);
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "Sin conexion" });
        switch (err.response.status) {
          case 401:
            reject({ error: true, message: "Sesion invalida" });
            break;

          case 403:
            reject({ error: true, message: "Permisos insuficientes" });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

async function getNewQR({ device_id }) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "get",
      timeout: 3 * 1000,
      url: `${baseUrl}/generate?dui=${device_id}`,
    })
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          resolve({ error: false, payload: { qr: data.qr } });
        } else {
          reject({
            error: true,
            message: "Error desconocido " + response.status,
          });
        }
      })
      .catch((err) => {
        if (!err.response)
          return reject({ error: true, message: "No tenes conexion" });
        switch (err.response.status) {
          case 401:
            reject({
              error: true,
              message: "Sesion invalida",
              statusCode: err.response.status,
            });
            break;

          case 403:
            reject({ error: true, message: "Permisos insuficientes" });
            break;

          case 500:
            reject({
              error: true,
              message: "Se produjo un error, intentalo denuevo más tarde",
            });
            break;

          default:
            reject({
              error: true,
              message: err?.response?.data?.message,
            });
            break;
        }
      });
  });
}

export const qrService = {
  getDevices,
  deleteDevice,
  registerDevice,
  getNewQR,
};
