import React, { useState, useEffect } from "react";

import loginImage from "./assets/images/27.webp";
import Button from "./components/Button";
import InputText from "./components/InputText";
import { Row, Col, Modal } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import SuccessImage from "./assets/images/359.webp";
import "./shared.css";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import useUser from "../hooks/useUser";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const {
    isLoginLoading,
    hasLoginError,
    loginMessage,
    verificationRequired,
    login,
    isLogged,
    sessionData,
  } = useUser();

  const [verificationModal, setVerificationModal] = useState(false);

  useEffect(() => {
    setVerificationModal(verificationRequired);
  }, [verificationRequired]);

  // FIX: dependency array error
  useEffect(() => {
    if (isLogged) {
      const redirect = searchParams.get("redirect");

      if (!redirect) {
        navigate(`/dashboard`, {
          state: { chg_password: sessionData?.chg_password ?? false },
        });
        return;
      }

      navigate(redirect);
    }
  }, [isLogged, login, navigate, searchParams, sessionData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username && password && !isLoginLoading) {
      login({ username, password });
    }
  };

  return (
    <section className="centered_section">
      <Modal
        show={verificationModal}
        onHide={() => {
          setVerificationModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cuenta no verificada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img style={{ width: "60%" }} src={SuccessImage} alt="Success" />
            <div className="email-confirmation__description">
              Enviamos un mail a tu casilla de correo, por favor verifica tu
              cuenta
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Link
        to={searchParams.get("from") ?? "/"}
        className="login__image-arrowBack"
      >
        <ArrowLeft />
        <span className="login__image-backText">Volver</span>
      </Link>
      <Row className="login__container">
        <Col sm={6} className="login__image-container">
          <img alt="Imagen de inicio de sesion" src={loginImage} />
        </Col>
        <Col className="login__form-container">
          <div>
            <h2>Hola! Ingresa tu mail y contraseña</h2>
            <form onSubmit={handleSubmit}>
              <InputText
                hasError={hasLoginError}
                disabled={isLoginLoading}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                name="email"
                label="Email"
              />
              <InputText
                hasError={hasLoginError}
                disabled={isLoginLoading}
                onChange={(e) => setPassword(e.target.value)}
                isPassword={true}
                value={password}
                name="password"
                label="Contraseña"
              />

              <div className="login__form-button">
                <Button
                  disabled={isLoginLoading}
                  filled={true}
                  text="Ingresar"
                  large={true}
                />
              </div>
              <div className="login__form-button">
                <Link to="/register">
                  <Button
                    disabled={isLoginLoading}
                    filled={false}
                    text="Crear una cuenta"
                    large={true}
                  />
                </Link>
              </div>

              <div
                style={{ visibility: hasLoginError ? "visible" : "hidden" }}
                className="login__form-error"
              >
                {loginMessage}
              </div>
              <div className="login__form-hasaccount">
                ¿Olvidaste tu contraseña? <Link to="/restore"> Recuperar </Link>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default LoginPage;
