import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header />
      <Container>
        <h1>Terminos y condiciones</h1>
        <p>En proceso...</p>
      </Container>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
