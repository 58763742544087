import { Button } from "react-bootstrap";
import {
  ArrowLeft,
  Clipboard,
  Clock,
  PencilSquare,
  Trash,
} from "react-bootstrap-icons";
import { DashboardItem } from "./components/DashboardItem";
import { Image } from "./components/Image";
import { Link } from "./components/Link";
import { data, pronounSwitch } from "./data";
import { useBusinessTypeContext } from "./index";

import ShiftsTableHighlighted from "./images/shifts_table_gestionar-turnos.png";
import ShiftsTable from "./images/shifts_table.png";
import ShiftsTableList from "./images/shift_table_list.png";
import ShiftsTableListFilled from "./images/shifts_list_filled.png";
import ShiftsCreate from "./images/shifts_create.png";
import ShiftsAsignmentCreate from "./images/shifts_asignment_create.png";
import ShiftsAsignmentCreateHighlighted from "./images/shifts_asignment_create_highlighted.png";
import ShiftsAsignmentCreateSelected from "./images/shifts_asignment_create_selected.png";
import ShiftsAsignmentDetail from "./images/shifts_asignment_detail.png";
import ShiftsAsignmentTable from "./images/shifts_asignment_table.png";

function Shifts() {
  const { businessType } = useBusinessTypeContext();
  return (
    <div>
      <p>
        Si los empleados deben cumplir un horario especifico, Praesenti te
        permite crear turnos y asociarlos a los empleados correspondientes. Esto
        permitira un mejor manejo de los horarios y podran utilizar la
        herramienta de detección de anomalias y análisis de fichadas (explicado
        en{" "}
        <Link href="Anomalias%20y%20analisis%20de%20fichadas">Anomalias</Link>)
      </p>
      <p>
        En {pronounSwitch(businessType, "nuestro", "nuestra")} {businessType}{" "}
        habra {data.get(businessType)?.shifts.length} turnos.
      </p>

      <div style={{ display: "flex" }}>
        <div
          style={{
            backgroundColor: "#e7e8ed",
            padding: "1rem 2rem 1rem 1rem",
            margin: "1rem 0",
            borderRadius: "5px",
            width: "auto",
          }}
        >
          <ul style={{ margin: "0" }}>
            {data.get(businessType)?.shifts.map(({ name, hours, days }) => (
              <li>
                {name}: {hours} ({days.join(", ")})
              </li>
            ))}
          </ul>
        </div>
      </div>

      <p>
        Los turnos pueden variar en horario de entrada y salida e inclusive en
        los dias en los que apliquen. Los turnos tienen nombres que los
        identifican, por ejemplo:
      </p>
      <p>{data.get(businessType).texts.shiftsIntro}</p>

      <p>
        Para gestionar los turnos debera acceder a su sección en el{" "}
        <b>Dashboard</b>
      </p>
      <DashboardItem
        active
        text="Turnos"
        Icon={Clock}
        to={"dashboard/employees"}
      />
      <h3>Crear turnos</h3>
      <p>
        Para gestionar los turnos, es decir, crear/editar/eliminar los turnos
        debemos presionar el botón <Button size="sm">Gestionar turnos</Button>{" "}
        que se encuentra en el panel.
      </p>
      <Image src={ShiftsTableHighlighted} />
      <p>
        Esto nos llevara a una nueva sección, desde donde podremos modificar los
        <b>turnos</b>, lo que no se debe confundir con las{" "}
        <b>asignaciones de turnos</b>, que describiremos mas adelante.
      </p>
      <Image src={ShiftsTableList} />
      <p>
        Al presionar el botón de{" "}
        <Button variant="success" size="sm">
          Nuevo turno
        </Button>{" "}
        se nos abrira un formulario solicitandonos los datos del turno
      </p>
      <Image width="40%" src={ShiftsCreate} />
      <p>
        La marca de <b>Prioritario</b> nos permitira sobreescribir a todos los
        turnos cuyos dias coincidan con el mismo y a la hora de calcular la
        asistencia, los otros turnos no seran tomados en cuenta.
      </p>
      <p>Una vez creados los turnos nuestra tabla se vera asi: </p>
      <Image src={ShiftsTableListFilled} />
      <h3>Asignaciones de turnos</h3>
      <p>
        Ahora si vamos a ver como se asignan los turnos a los empleados, podemos
        presionar en el botón de{" "}
        <b>
          <ArrowLeft />
          <span className="employee-detail__image-backText">Volver</span>
        </b>{" "}
        que se encuentra arriba a la izquierda
      </p>
      <p>
        Las asignaciones son disposiciones de turnos en cierto periodo. Tienen
        una fecha de inicio y estaran vigentes hasta la fecha de inicio de la
        proxima asignacion. Es una manera de organizar que empleados asisten a
        que turnos en determinado periodo.
      </p>
      <p>
        Si en tu organización no hay cambios de turnos y todos siempre
        permanecen en las mismas bandas horarias, solo sera necesario una unica
        asignacion. Pero si en tu <b>{businessType}</b> los empleados rotan de
        horario cada 2 meses por ejemplo, entonces deberan crear estas
        asignaciones cada vez que deseen rotar los turnos.
      </p>
      <h3>Crear asignacion</h3>
      <p>
        Para crear una nueva asignación se debe presionar el botón de{" "}
        <Button variant="success" size="sm">
          Nueva asignacion
        </Button>
      </p>
      <Image src={ShiftsTable} />
      <p>
        Lo que abrira un formulario solicitandole la información de la
        asignacion
      </p>
      <Image width="50%" src={ShiftsAsignmentCreate} />
      <p>
        Debera seleccionar los empleados que quiere que sean parte del turno
        elegido clickeandolos.
      </p>
      <Image width="50%" src={ShiftsAsignmentCreateHighlighted} />
      <span id="shifts_asignment_detail" />
      <Image width="50%" src={ShiftsAsignmentCreateSelected} />
      <p>
        Una vez creada la asignación sera automaticamente redirigido a la pagina
        de detalle de la asignacion, ya que solo se habra asignado el turno que
        selecciono en el formulario. Para agregar mas turnos a una asignacion
        debera presionar el botón de{" "}
        <Button variant="success" size="sm">
          Nuevo turno
        </Button>{" "}
        y completar un formulario muy similar al anterior
      </p>
      <Image src={ShiftsAsignmentDetail} />
      <p>
        Para volver a la vista general de las asignaciones puede presionar el
        botón de{" "}
        <b>
          <ArrowLeft />
          <span className="employee-detail__image-backText">Volver</span>
        </b>{" "}
      </p>

      <h3>Editar y duplicar asignaciones</h3>
      <p>
        En caso de necesitar agregar/eliminar personal de una asignacion, o
        agregar/eliminar un turno de una asignación puede hacerlo presionando el
        boton{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>{" "}
        en la tabla de asignaciones, lo que lo llevara al detalle de la
        asignación que vimos anteriormente{" "}
        <Link href="shifts_asignment_detail">aqui</Link>
      </p>
      <p>
        En caso de querer crear una nueva asignación muy similar a una anteror o
        incluso identica pero para otra fecha, puede hacerlo presionando el
        boton{" "}
        <Button
          style={{ backgroundColor: "#4c399f", borderColor: "#4c399f" }}
          size="sm"
        >
          <Clipboard />
        </Button>{" "}
        en la tabla de asignaciones, lo que duplicara la asignación con la fecha
        de inicio que le indique
      </p>
      <p>
        Eliminar una asignación cuya fecha de inicio paso, es decir que esta o
        estuvo activa durante un período no es posible desde la vista general,
        es por esta razón que el boton{" "}
        <Button variant="danger" disabled size="sm">
          <Trash />
        </Button>{" "}
        esta deshabilitado. Si realmente deseara eliminar esta asignacion,
        debera entrar en el detalle, con el boton{" "}
        <Button size="sm">
          <PencilSquare />
        </Button>
        , y eliminar uno por uno los turnos que conforman la asignacion
      </p>
      <Image src={ShiftsAsignmentTable} />
      <p></p>
    </div>
  );
}

export { Shifts };
