import SidebarItem from "../../Dashboard/components/SidebarItem";
import "../../Dashboard/layout.css";

function DashboardItem({ text, Icon, active, to }) {
  const href = `login?redirect=/${to}`;
  return (
    <div className="docs__sidebar-item-container">
      <div className="docs__sidebar-item">
        <SidebarItem
          id="sidebar-dashboard"
          onClick={() => {}}
          opened={true}
          active={active ? `/${href}` : ""}
          icon={<Icon size={20} />}
          to={href}
          text={text}
        />
      </div>
    </div>
  );
}

export { DashboardItem };
