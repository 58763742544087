import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { qrService } from "../services/qr";

export default function useDevices() {
  const [state, setState] = useState({
    loading: false,
    data: [],
    error: false,
    errorMessage: null,
  });

  const getDeviceID = () => {
    let uuid = window.localStorage.getItem("uuid");
    if (!uuid) uuid = generateDeviceID();
    return uuid;
  };

  const generateDeviceID = () => {
    const uuid = uuidv4();
    window.localStorage.setItem("uuid", uuid);
    return uuid;
  };

  // FIX: dependency array
  const getDevices = useCallback(
    ({ token }) => {
      setState({ loading: true, data: [], error: false, errorMessage: null });

      qrService
        .getDevices({ token })
        .then((data) => {
          setState({ loading: false, data, error: false, errorMessage: null });
          //TODO: SET TOKEN!!!
        })
        .catch((err) => {
          setState({
            errorMessage: err.message,
            loading: false,
            error: true,
            data: [],
          });
        });
    },
    [state]
  );

  // FIX: dependency array
  const deleteDevice = useCallback(
    ({ token, device_id }) => {
      setState({ ...state, loading: true, error: false, errorMessage: null });

      qrService
        .deleteDevice({ token, device_id })
        .then(() => {
          setState({
            ...state,
            loading: false,
            error: false,
            errorMessage: null,
          });
          getDevices({ token });
          //TODO: SET TOKEN!!!
        })
        .catch((err) => {
          setState({
            ...state,
            errorMessage: err.message,
            loading: false,
            error: true,
          });
        });
    },
    [state]
  );

  // FIX: dependency array
  const registerDevice = useCallback(
    ({ token, device_id, alias = "" }) => {
      setState({ ...state, loading: true, error: false, errorMessage: null });

      qrService
        .registerDevice({ token, device_id, alias })
        .then(() => {
          setState({
            ...state,
            loading: false,
            error: false,
            errorMessage: null,
          });
          getDevices({ token });
          //TODO: SET TOKEN!!!
        })
        .catch((err) => {
          setState({
            ...state,
            errorMessage: err.message,
            loading: false,
            error: true,
          });
        });
    },
    [state]
  );

  return {
    getDeviceID,
    getDevices,
    deleteDevice,
    registerDevice,
    errorMessage: state.errorMessage,
    error: state.error,
    loading: state.loading,
    data: state.data,
  };
}
