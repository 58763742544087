import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import useUser from "../hooks/useUser";
import Button from "../shared/components/Button";
import Header from "../shared/components/Header";
import { Link } from "react-router-dom";
import PaymentUpdatedImage from "../shared/assets/images/127.webp";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UpdatedPage() {
  const navigate = useNavigate();
  const params = useLocation();

  const price = params.state?.price;
  const employees = params.state?.employees;

  const { isLogged } = useUser();

  useEffect(() => {
    if (!isLogged || !employees || !price) {
      navigate(`/pricing`);
      return;
    }
  }, [isLogged, navigate, price, employees]);

  return (
    <>
      <Header />
      <Container className="centered_section centered_section-404">
        <Row className="error404__section">
          <Col>
            <img
              className="error404__image"
              src={PaymentUpdatedImage}
              alt="updated payment"
            />
          </Col>
          <Col>
            <h2 className="error404__title">Actualizamos tu plan!</h2>
            <p
              style={{
                fontSize: "1.5rem",
                margin: "2rem 0",
                textAlign: "center",
              }}
            >
              Ahora podes tener hasta {employees} empleados
            </p>

            <div>
              <p
                style={{
                  fontSize: "1.3rem",
                  textAlign: "center",
                }}
              >
                Tu próximo pago será de:
              </p>
              <div
                className="tier-card__price-container"
                style={{ margin: "2rem 0", fontSize: "1.7rem" }}
              >
                <span className="tier-card__price-container__symbol">$</span>
                <span
                  className="tier-card__price-container__title"
                  style={{
                    fontSize: "2.5rem",
                  }}
                >
                  {price?.toLocaleString()}
                </span>
                <span className="tier-card__price-container__text">
                  &nbsp;ars/mes*
                </span>
              </div>
            </div>
            <Link to="/">
              <Button filled={true} text="Volver al inicio" large={true} />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
