import Header from "../shared/components/Header";
import Footer from "./components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import GearsImage from "./assets/images/gears.webp";
import { useEffect } from "react";

const intervalTime = 2 * 60 * 1000; // 2 min (min * sec * msec)

const TermsAndConditions = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, intervalTime);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Header />
      <Container className="centered_section centered_section-404">
        <Row className="error404__section">
          <Col>
            <img
              className="error404__image"
              src={GearsImage}
              alt="payment paused"
            />
          </Col>
          <Col>
            <h1 className="error404__title">Estamos en Mantenimiento :(</h1>
            <p
              style={{
                fontSize: "1.2rem",
                margin: "2rem 0",
              }}
            >
              Por favor sea paciente, estamos mejorando la calidad de nuestro
              producto para mejorar su experiencia como usuario.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
