import React from "react";
import { Spinner } from "react-bootstrap";
import "./Button.css";

function Button(params) {
  let classes = "button__container ";
  classes += params.filled
    ? "button__container-filled "
    : "button__container-unfilled ";
  classes += params.large ? "button__container-large " : "";
  classes +=
    params.disabled || params.loading ? "button__container-disabled" : "";

  return (
    <button
      id={params.id}
      style={
        params.color
          ? { backgroundColor: params.color, ...params.style }
          : params.style
      }
      className={classes}
      onClick={params.disabled || params.loading ? null : params.onClick}
    >
      {params.loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}{" "}
      {params.text ?? params.children}
    </button>
  );
}

export default Button;
