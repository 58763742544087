import React, { useState } from "react";

import { useParams, Link } from "react-router-dom";
import { sessionService } from "../services/session";

import "./shared.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Spinner } from "react-bootstrap";
import Header from "./components/Header";
import SuccessImage from "./assets/images/403.webp";
import ErrorImage from "./assets/images/128.webp";
import RestoreImage from "./assets/images/225.webp";
import Button from "./components/Button";
import InputText from "./components/InputText";

const Confirm = () => {
  let { token } = useParams();
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);

  function restorePassword() {
    setState({ loading: true, error: false, success: false });

    if (!passwordValid)
      return setState({
        loading: false,
        error: true,
        success: false,
        message: "La contraseña introducida no cumple los requisitos minimos",
      });
    if (password !== passwordConfirmation)
      return setState({
        loading: false,
        error: true,
        success: false,
        message: "Las contraseñas deben coincidir",
      });

    sessionService
      .restorePassword({ token, newPassword: password })
      .then(() => {
        setState({ loading: false, error: false, success: true });
      })
      .catch((err) => {
        setState({ loading: false, error: true, message: err.message });
      });
  }

  return (
    <>
      <Header />
      <section className="centered_section">
        <Modal
          show={state.error}
          onHide={() => {
            setState({});
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Se produjo un error"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img style={{ width: "60%" }} src={ErrorImage} alt="Error" />
              <div
                className="email-confirmation__description"
                alt="Email confirmation"
              >
                {state.message ??
                  "Ocurrio un error, revisa que tu contraseña sea valida y volve a intentarlo"}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {state.loading ? (
          <>
            <Spinner
              style={{
                width: "6vh",
                height: "6vh",
                color: "#4c399f",
                borderWidth: "0.4em",
              }}
              animation="border"
              size="lg"
            />
            <h2 className="email-confirmation__title">Restableciendo</h2>
          </>
        ) : (
          <>
            {state.success ? (
              <>
                <img
                  className="email-confirmation__image"
                  src={SuccessImage}
                  alt="Success"
                />
                <h2 className="email-confirmation__title">LISTO!</h2>
                <div className="email-confirmation__description">
                  Restableciste tu contraseña, ya podes iniciar sesion
                </div>
                <Link to="/login">
                  <Button filled={true} text="Iniciar Sesion"></Button>
                </Link>
              </>
            ) : (
              <div>
                <div className="email-confirmation__image-container">
                  <img
                    className="email-confirmation__image"
                    src={RestoreImage}
                    alt="Email confirmation"
                  />
                </div>
                <h2 className="email-confirmation__title">
                  Elige tu nueva contraseña
                </h2>
                <div className="email-confirmation__description">
                  Introduce tu nueva contraseña
                </div>
                <div>
                  <InputText
                    isPassword
                    passwordValidation
                    name="password"
                    value={password}
                    onChange={(e, v) => {
                      setPassword(e.target.value);
                      setPasswordValid(v);
                    }}
                    id="password"
                    label="Contraseña"
                  />
                  <InputText
                    hasError={
                      passwordConfirmation && passwordConfirmation !== password
                    }
                    isPassword
                    name="password"
                    value={passwordConfirmation}
                    onChange={(e) => {
                      setPasswordConfirmation(e.target.value);
                    }}
                    id="password"
                    label="Repetir contraseña"
                  />
                  <Button
                    disabled={
                      password === "" ||
                      passwordConfirmation !== password ||
                      !passwordValid
                    }
                    loading={state.loading}
                    onClick={restorePassword}
                    large={true}
                    filled={true}
                    text="Restablecer contraseña"
                  ></Button>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default Confirm;
