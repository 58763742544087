import Header from "../shared/components/Header";
import Footer from "../shared/components/Footer";
import {
  Container,
  Col,
  Row,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import { CheckLg } from "react-bootstrap-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import usePayments from "../hooks/usePayments";
import useUser from "../hooks/useUser";
import ErrorAlert from "../shared/components/ErrorAlert";
import "./styles.css";

const ButtonText = ({ price, selected, disabled }) => {
  if (selected) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckLg />
        <p style={{ margin: 0, marginLeft: "1rem", padding: 0 }}>Plan actual</p>
      </div>
    );
  }

  if (disabled) {
    return <p style={{ margin: 0, padding: 0 }}>Deshabilitado</p>;
  }

  if (price === 0) {
    return <p style={{ margin: 0, padding: 0 }}>Empezar ahora</p>;
  }

  return <p style={{ margin: 0, padding: 0 }}>Adquirir</p>;
};

function TierCard({ id, name, employees, price, selected, disabled }) {
  const navigate = useNavigate();
  const [tooltipOpen, tooltipOpenSet] = useState(false);

  return (
    <OverlayTrigger
      onToggle={(isOpen) => {
        if (!disabled) return;
        tooltipOpenSet(isOpen);
      }}
      show={tooltipOpen}
      overlay={
        <Tooltip>
          Para bajar de plan contactese con support@praesenti.com.ar
        </Tooltip>
      }
    >
      <div
        className={
          selected
            ? "tier-card-selected"
            : disabled
            ? "tier-card-disabled"
            : "tier-card"
        }
      >
        <div
          className={
            selected
              ? "tier-card__container-selected"
              : disabled
              ? "tier-card__container-disabled"
              : "tier-card__container"
          }
        >
          <h2 className="tier-card__title">{name}</h2>
          {price === 0 ? (
            <span className="tier-card__price-container__title">Gratis</span>
          ) : (
            <div className="tier-card__price-container">
              <span className="tier-card__price-container__symbol">$</span>
              <span className="tier-card__price-container__title">
                {price.toLocaleString()}
              </span>
              <span className="tier-card__price-container__text">
                &nbsp;ars/mes*
              </span>
            </div>
          )}
        </div>

        <p style={{ textAlign: "center" }}>
          {employees
            ? `Hasta ${employees} empleados`
            : "Sin limite de empleados"}
        </p>

        <button
          className={
            selected
              ? "tier-card__button-selected"
              : disabled
              ? "tier-card__button-disabled"
              : "tier-card__button"
          }
          onClick={() => {
            navigate(`${id}`, { state: { isFree: price === 0 } });
          }}
          disabled={disabled || selected}
        >
          <ButtonText price={price} selected={selected} disabled={disabled} />
        </button>
      </div>
    </OverlayTrigger>
  );
}

function Content({ data, loading, error, message, orgTier }) {
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <ErrorAlert error={message} dismissible={false} />;
  }

  return data.map((plan) => (
    <Col key={plan.id} style={{ display: "flex", justifyContent: "center" }}>
      <TierCard
        {...plan}
        selected={plan.id === orgTier}
        disabled={plan.id < orgTier}
      />
    </Col>
  ));
}

export default function PricingPage() {
  const { sessionData, validatePermissions } = useUser();
  const { getTiers, loading, error, message, data } = usePayments();

  useEffect(() => {
    getTiers();
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header />
      <Container
        style={{ flexGrow: "999", display: "flex", flexDirection: "column" }}
      >
        {sessionData?.org?.tier_status === "pending" && (
          <Alert variant="warning" style={{ marginTop: "1rem" }}>
            {validatePermissions({ scopesNeeded: ["EMPLOYEE:CREATE"] }) ? (
              <p style={{ margin: "0" }}>
                Su suscripción a Praesenti esta pendiente! Contactese con
                <b>&nbsp;help@praesenti.com.ar</b> para resolver el problema.
              </p>
            ) : (
              <p style={{ margin: "0" }}>
                La suscripción a Praesenti esta pendiente! Hable con el
                encargado para continuar usando praesenti con normalidad.
              </p>
            )}
          </Alert>
        )}

        {sessionData?.org?.tier_status === "cancelled" && (
          <Alert variant="danger" style={{ marginTop: "1rem" }}>
            {validatePermissions({ scopesNeeded: ["EMPLOYEE:CREATE"] }) ? (
              <p style={{ margin: "0" }}>
                Su suscripción a Praesenti esta cancelada! Contactese con
                <b>&nbsp;help@praesenti.com.ar</b> para resolver el problema.
              </p>
            ) : (
              <p style={{ margin: "0" }}>
                La suscripción a Praesenti esta cancelada! Hable con el
                encargado para continuar usando praesenti con normalidad.
              </p>
            )}
          </Alert>
        )}
        <div
          style={{
            flexGrow: "999",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <h1>Precios</h1>
          <p>
            Elegi el plan que mejor se ajuste a tu necesidad y pone a
            <b>&nbsp;Praesenti</b> a trabajar para tu empresa en cuestión de
            minutos!
          </p>
          <div style={{ margin: "2rem 0" }} />
          <Col>
            <Row
              xs="1"
              md="2"
              xxl="5"
              style={{ justifyContent: "space-evenly" }}
            >
              <Content
                data={data}
                loading={loading}
                error={error}
                message={message}
                orgTier={sessionData?.org?.org_tier}
              />
            </Row>
          </Col>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
