import Header from "../shared/components/Header";
import { Col, Row, Container } from "react-bootstrap";
import {
  Book,
  Briefcase,
  Clipboard,
  UpcScan,
  Collection,
  Clock,
  Watch,
  ExclamationTriangle,
  People,
  List,
  X,
} from "react-bootstrap-icons";
import Footer from "../shared/components/Footer";
import "./styles.css";
import { Employees } from "./Employees";
import { GettingStarted } from "./GettingStarted";
import { Devices } from "./Devices";
import { Registers } from "./Registers";
import { Areas } from "./Areas";
import { Shifts } from "./Shifts";
import { Admins } from "./Admins";
import { Anomalies } from "./Anomalies";
import { ParticularShifts } from "./ParticularShifts";
import { AdvancedUseCases } from "./AdvancedUseCases";
import { useState, createContext, useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../shared/assets/images/logo.webp";
import Button from "../shared/components/Button";

const BusinessTypeContext = createContext({
  businessType: "Restaurant",
  setBusinessType: (_value) => {},
});

function BusinessTypeContextProvider({ children }) {
  const [businessType, setBusinessType] = useState("Restaurant");
  return (
    <BusinessTypeContext.Provider value={{ businessType, setBusinessType }}>
      {children}
    </BusinessTypeContext.Provider>
  );
}

export function useBusinessTypeContext() {
  return useContext(BusinessTypeContext);
}

function DocumentationPageWrapper({ stuff }) {
  const [navBarOpen, setNavBarOpen] = useState(false);

  return (
    <>
      <div className="d-block d-lg-none">
        <div className="header" style={{ position: "fixed" }}>
          <Container
            className="header__container"
            style={{ flexDirection: "row" }}
          >
            <Link to="/">
              <img src={Logo} className="header__image" alt="Logo" />
            </Link>

            <div
              className="header__button-container"
              style={{ marginBottom: "0" }}
            >
              <Button
                onClick={() => setNavBarOpen((prev) => !prev)}
                filled={false}
                text={navBarOpen ? <X size={25} /> : <List size={25} />}
              />
            </div>
          </Container>
          <Container>
            {navBarOpen && (
              <div style={{ marginBottom: "2rem", marginTop: "1rem" }}>
                <h2 style={{ fontSize: "1.5rem", fontStyle: "bold" }}>
                  Indice
                </h2>

                <ul
                  style={{
                    listStyle: "none",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {stuff.map(({ name, Icon, margin }) => (
                    <a
                      onClick={() => setNavBarOpen(false)}
                      href={`/docs/#${name}`}
                      key={`sidebar-${name}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <li className="docs__sidebar__item" style={{ margin }}>
                        {Icon ? (
                          <span style={{ marginRight: "0.5rem" }}>
                            <Icon />
                          </span>
                        ) : (
                          ""
                        )}
                        {name}
                      </li>
                    </a>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </div>
        <div style={{ height: "75px" }} />
      </div>
      <Container style={{ marginTop: "2rem" }}>
        <Row style={{ flexGrow: 1, margin: 0 }}>
          <Col md={1} lg={2} className="d-none d-lg-block docs__index">
            <h2 style={{ fontSize: "1.5rem", fontStyle: "bold" }}>Indice</h2>

            <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
              {stuff.map(({ name, Icon, margin }) => (
                <a
                  href={`/docs/#${name}`}
                  key={`sidebar-${name}`}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <li className="docs__sidebar__item" style={{ margin }}>
                    {Icon ? (
                      <span style={{ marginRight: "0.5rem" }}>
                        <Icon />
                      </span>
                    ) : (
                      ""
                    )}
                    {name}
                  </li>
                </a>
              ))}
            </ul>
          </Col>
          <Col
            lg={{ offset: 1 }}
            md={{ offset: 0 }}
            style={{ height: "100%", padding: "0" }}
          >
            <BusinessTypeContextProvider>
              {stuff.map(({ name, Component }) => (
                <div style={{ marginBottom: "8rem" }} key={name}>
                  <span className="linked-heading_target" id={name} />
                  <h1 style={{ fontSize: "3rem" }}>
                    <a className="docs__section-title" href={`/docs/#${name}`}>
                      {name}
                    </a>
                  </h1>
                  <Component key={name} title={name} />
                </div>
              ))}
            </BusinessTypeContextProvider>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default function DocumentationPage() {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="d-none d-lg-block">
        <Header />
      </div>
      <DocumentationPageWrapper
        stuff={[
          {
            name: "Uso basico",
            Icon: Book,
            Component: GettingStarted,
          },
          {
            name: "Empleados",
            margin: "0 0 0 1rem",
            Icon: Briefcase,
            Component: Employees,
          },
          {
            margin: "0 0 0 1rem",
            name: "Dispositivos",
            Icon: UpcScan,
            Component: Devices,
          },
          {
            margin: "0 0 0 1rem",
            name: "Fichadas",
            Icon: Clipboard,
            Component: Registers,
          },
          {
            margin: "2rem 0 0 0",
            name: "Uso avanzado",
            Icon: Book,
            Component: AdvancedUseCases,
          },
          {
            margin: "0 0 0 1rem",
            name: "Areas y categorias",
            Icon: Collection,
            Component: Areas,
          },
          {
            margin: "0 0 0 1rem",
            name: "Turnos",
            Icon: Clock,
            Component: Shifts,
          },
          {
            margin: "0 0 0 1rem",
            name: "Turnos particulares",
            Icon: Watch,
            Component: ParticularShifts,
          },
          {
            margin: "0 0 0 1rem",
            name: "Anomalias y análisis de fichadas",
            Icon: ExclamationTriangle,
            Component: Anomalies,
          },
          {
            margin: "0 0 0 1rem",
            name: "Administradores",
            Icon: People,
            Component: Admins,
          },
        ]}
      />
      <Footer />
    </div>
  );
}
