import { DashboardItem } from "./components/DashboardItem";
import {
  BriefcaseFill,
  EyeFill,
  UpcScan,
  BoxArrowUpRight,
} from "react-bootstrap-icons";
import { Link } from "./components/Link";
import { Button } from "react-bootstrap";
import { Image } from "./components/Image";
import CustomButton from "../shared/components/Button";
import EmployeeTable from "./images/employee_table.png";
import EmployeeQr from "./images/employee_qr.png";
import DevicesModal from "./images/devices_create.png";

function GettingStarted() {
  return (
    <div>
      <p>
        Aunque Praesenti soporta funcionalidades avanzadas como{" "}
        <Link href="Areas%20y%20categorias">areas y categorias</Link>,{" "}
        <Link href="Turnos">turnos</Link> o{" "}
        <Link href="Anomalias%20y%20analisis%20de%20fichadas">
          análisis de anomalias
        </Link>{" "}
        entre otras. Tambien es posible utilizarla de manera muy basica y
        sencilla. Para lo que vas a necesitar solo 2 cosas, <b>Empleados</b> y{" "}
        <b>Dispositivos</b>.
      </p>
      <h3>Empleados</h3>
      <p>
        Esta es una breve descripción de como funcionan los empleados en{" "}
        Praesenti, si es la primera vez que utilizas el producto, te
        recomendamos leer la descripción detallada en la sección de{" "}
        <Link href="Empleados">empleados</Link>
      </p>
      <p>
        Para que tus empleados puedan registrar sus ingresos y egresos en
        Praesenti, deben primero estar registrados en la plataforma. Para poder
        darlos de alta, dirigite a la sección de empleados en el{" "}
        <b>Dashboard</b>
      </p>
      <DashboardItem
        active
        text="Empleados"
        Icon={BriefcaseFill}
        to={"dashboard/employees"}
      />
      <p style={{ marginTop: "2rem" }}>
        Una vez en la sección, crea tus empleados con el botón de{" "}
        <Button size="sm" variant="success">
          Nuevo empleado
        </Button>{" "}
        que se encuentra arriba a la derecha.
      </p>
      <p>
        Cuando tengas a todos tus empleados registrados y hayan descargado la{" "}
        <b>aplicacion</b>, disponible tanto para{" "}
        <a href="https://play.google.com/store/apps/details?id=ar.com.dpsonline.praesenti_app&gl=US">
          <b>android</b>
        </a>{" "}
        como{" "}
        <a href="https://apps.apple.com/us/app/id1618767336">
          <b>ios</b>
        </a>
        . Deberas asociar sus celulares con su usuario en Praesenti, para eso
        utiliza el botón de{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#3c4b64", borderColor: "#3c4b64" }}
        >
          <EyeFill />
        </Button>{" "}
        en la tabla de empleados.
      </p>
      <Image src={EmployeeTable} />
      <p>
        Una vez en el detalle del empleado, presionando el boton{" "}
        <Button size="sm" variant="dark">
          Obtener QR &nbsp;
          <UpcScan />
        </Button>
        , aparecera un <b>QR</b> en pantalla, el cual debera ser leído por el
        empleado mediante la app de Praesenti previamente descargada.
      </p>
      <Image width="40%" src={EmployeeQr} />
      <p>
        Si aun tiene alguna duda, revise la guía sobre empleados mas detallada{" "}
        <Link href="Empleados">aqui</Link>
      </p>
      <br />
      <h3>Dispositivos</h3>
      <p>
        Una vez completado el paso anterior, para que los empleados puedan
        realizar sus fichadas sera necesario dejar disponible en todo momento (o
        al menos en los periodos laborales), un dispositivo, que puede ser una
        tablet, un celular o una computadora, conectada a internet y accesible
        por los empleados.
      </p>
      <p>
        Este dispositivo sera el encargado de mostrar los QRs que deberan ser
        leidos por los empleados al momento de ingresar o salir del puesto de
        trabajo.
      </p>
      <p>
        Para configurarlo, debera iniciar sesión en el dispositivo que desea
        configurar y dirigirse a la sección de <b>Dispositivos</b>.
      </p>
      <DashboardItem
        active
        text="Dispositivos"
        Icon={UpcScan}
        to={"dashboard/qr"}
      />
      <p>
        Para que este dispositivo pueda funcionar, es necesario{" "}
        <b>Permitirlo</b> presionando el botón de{" "}
        <Button
          size="sm"
          style={{ backgroundColor: "#4c399f", borderColor: "#4c399f" }}
          filled="true"
        >
          Permitir este dispositivo
        </Button>
      </p>
      <Image width="50%" src={DevicesModal} />
      <p>
        Y listo! ahora para hacer visible el QR en pantalla y que tus empleados
        puedan fichar solo presiona el botón de
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <CustomButton>
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BoxArrowUpRight />
            <span style={{ marginLeft: "0.5rem" }}>Pantalla de QR</span>
          </span>
        </CustomButton>
      </div>

      <p>
        Si aun tiene alguna duda, revise la guía sobre dispositivos mas
        detallada <Link href="Dispositivos">aqui</Link>
      </p>
    </div>
  );
}

export { GettingStarted };
