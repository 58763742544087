import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContextProvider } from "../context/UserContext";
import { Spinner } from "react-bootstrap";

import loadable from "@loadable/component";
import config from "../config";

import LandingPage from "../Landing";
import PricingPage from "../Pricing";
import PricingCheckout from "../Pricing/checkout";
import UpdatedPage from "../Pricing/updated";
import PausedPage from "../Pricing/paused";
import NotFound from "../shared/NotFound";
import RegisterPage from "../shared/RegisterPage";
import RestorePassword from "../shared/RestorePassword";
import RestoreRequest from "../shared/RestoreRequest";
import Confirm from "../shared/Confirm";
import LoginPage from "../shared/LoginPage";
import QrPage from "../shared/qr";
import TermsAndConditions from "../shared/TermsAndConditions";
import IOSTermsAndConditions from "../shared/ios/TermsAndConditions";
import IOSPrivacy from "../shared/ios/Privacy";
import Backurl from "../Pricing/backurl";
import MaintenancePage from "../shared/Maintenance";
import DocumentationPage from "../Documentation";

const DashboardPage = loadable(() => import("../Dashboard"));

const maintenanceAlertHOC = (element) => {
  if (config.inMaintenance === "true") {
    return <MaintenancePage />;
  }

  return element;
};

const App = () => (
  <UserContextProvider>
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="pricing" element={maintenanceAlertHOC(<PricingPage />)} />
        <Route
          path="pricing/:planId"
          element={maintenanceAlertHOC(<PricingCheckout />)}
        />
        <Route
          path="payments/updated"
          element={maintenanceAlertHOC(<UpdatedPage />)}
        />
        <Route
          path="payments/paused"
          element={maintenanceAlertHOC(<PausedPage />)}
        />
        <Route
          path="payments/backurl/:externalReference"
          element={maintenanceAlertHOC(<Backurl />)}
        />
        <Route
          path="register"
          element={maintenanceAlertHOC(<RegisterPage />)}
        />
        <Route
          path="confirm/:token"
          element={maintenanceAlertHOC(<Confirm />)}
        />
        <Route
          path="restore"
          element={maintenanceAlertHOC(<RestoreRequest />)}
        />
        <Route
          path="restore/:token"
          element={maintenanceAlertHOC(<RestorePassword />)}
        />
        <Route path="login" element={maintenanceAlertHOC(<LoginPage />)} />
        <Route path="qr" element={maintenanceAlertHOC(<QrPage />)} />
        <Route path="terms" element={<TermsAndConditions />} />
        <Route path="ios/terms" element={<IOSTermsAndConditions />} />
        <Route path="ios/privacy" element={<IOSPrivacy />} />
        <Route path="docs" element={<DocumentationPage />} />
        <Route
          path="dashboard/*"
          element={maintenanceAlertHOC(
            <DashboardPage
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    style={{ color: "#4c399f" }}
                    animation="border"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
            />
          )}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </UserContextProvider>
);

export default App;
