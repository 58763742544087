import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";

import "./shared.css";
import registerImage from "./assets/images/82.webp";
import SuccessImage from "./assets/images/359.webp";
import Button from "./components/Button";
import InputText from "./components/InputText";
import { Row, Col, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";
import useUser from "../hooks/useUser";
import Select from "./components/Select";
import PhoneInput from "react-phone-number-input";

const RegisterPage = () => {
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState("");
  const [org_name, setOrg_name] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [where, setWhere] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [tacChecked, setTacChecked] = useState(false);
  const {
    isLoginLoading,
    hasLoginError,
    register,
    isLogged,
    verificationRequired,
    loginMessage,
    registerErrorInput,
  } = useUser();
  const [verificationModal, setVerificationModal] = useState(false);
  const navigate = useNavigate();
  const params = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isLogged) {
      const redirect = searchParams.get("redirect");

      if (!redirect) {
        navigate("/dashboard");
        return;
      }

      navigate(redirect);
    }
  }, [isLogged, register, navigate, searchParams]);

  useEffect(() => {
    let username = params.state?.username;
    if (username) {
      setUsername(username);
    }
  }, [params]);

  useEffect(() => {
    setVerificationModal(verificationRequired);
  }, [verificationRequired]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      username &&
      password &&
      org_name &&
      name &&
      lastname &&
      !isLoginLoading &&
      passwordValid &&
      phone
    ) {
      register({ username, password, org_name, name, lastname, phone, where });
    }
  };

  return (
    <section className="centered_section">
      <Modal
        show={verificationModal}
        onHide={() => {
          setVerificationModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ya estas registrad@, un paso mas!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img style={{ width: "60%" }} src={SuccessImage} alt="Success" />
            <div className="email-confirmation__description">
              Enviamos un mail a tu casilla de correo, por favor verifica tu
              cuenta
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Link
        to={searchParams.get("from") ?? "/"}
        className="login__image-arrowBack"
      >
        <ArrowLeft />
        <span className="login__image-backText">Volver</span>
      </Link>

      <Row className="login__container">
        <Col className="login__form-container">
          <div>
            <h2>Registra tu empresa</h2>
            <form onSubmit={handleSubmit}>
              <InputText
                onChange={(e) => setOrg_name(e.target.value)}
                value={org_name}
                hasError={registerErrorInput.org_name}
                disabled={isLoginLoading}
                name="org_name"
                label="Nombre de la empresa"
              />

              <Row>
                <Col>
                  <InputText
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    hasError={registerErrorInput.email}
                    disabled={isLoginLoading}
                    name="email"
                    label="Email"
                  />
                </Col>
                <Col>
                  <div
                    style={{ minWidth: null }}
                    className="input__text-input-container"
                  >
                    <label className="input__text-label" htmlFor={"phone"}>
                      Telefono
                    </label>
                    <span>
                      <PhoneInput
                        className="input__phone-input"
                        defaultCountry="AR"
                        placeholder=""
                        disabled={isLoginLoading}
                        onChange={setPhone}
                        value={phone}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputText
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    hasError={registerErrorInput.name}
                    disabled={isLoginLoading}
                    name="name"
                    label="Nombre"
                  />
                </Col>
                <Col>
                  <InputText
                    onChange={(e) => setLastname(e.target.value)}
                    value={lastname}
                    hasError={registerErrorInput.lastname}
                    disabled={isLoginLoading}
                    name="lastname"
                    label="Apellido"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    label={
                      <>
                        {"Donde conociste praesenti "}
                        <span style={{ color: "#000000a0" }}>(opcional)</span>
                      </>
                    }
                    value={where}
                    name="where"
                    options={[
                      { id: "", name: "" },
                      { id: "instagram", name: "Instagram" },
                      { id: "socialmedia", name: "Otras redes sociales" },
                      { id: "recommendation", name: "Me lo recomendaron" },
                      { id: "web", name: "Buscando en la web" },
                      { id: "otro", name: "Otro..." },
                    ]}
                    setValue={(v) => setWhere(v)}
                  />
                </Col>
              </Row>
              <InputText
                onChange={(e, v) => {
                  setPassword(e.target.value);
                  setPasswordValid(v);
                }}
                passwordValidation
                value={password}
                hasError={registerErrorInput.password}
                disabled={isLoginLoading}
                name="password"
                label="Contraseña"
                isPassword={true}
              />

              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <div>
                    <input
                      id="tac"
                      type="checkbox"
                      className="checkout__checkbox"
                      checked={tacChecked}
                      onChange={() => {
                        setTacChecked((prev) => !prev);
                      }}
                    />
                  </div>
                  <label htmlFor="tac">
                    Acepto los{" "}
                    <Link to="/terms" target="_blank" rel="noopener noreferrer">
                      terminos y condiciones
                    </Link>
                  </label>
                </div>
              </div>

              <div className="login__form-button">
                <Button
                  disabled={isLoginLoading || !tacChecked}
                  filled={true}
                  text="Registrar empresa"
                  large={true}
                />
              </div>

              <div>
                <div
                  style={{ visibility: hasLoginError ? "visible" : "hidden" }}
                  className="login__form-error"
                >
                  {loginMessage ?? "ocurrio un error"}
                </div>
              </div>
            </form>
            <div className="login__form-hasaccount">
              ¿Ya tenes una cuenta? <Link to="/login"> Inicia sesión </Link>
            </div>
          </div>
        </Col>

        <Col sm={6} className="login__image-container">
          <img alt="Imagen de registro" src={registerImage} />
        </Col>
      </Row>
    </section>
  );
};

export default RegisterPage;
